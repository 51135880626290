// White input + label
input.white {
  color: $default-color !important;
  background-color: transparent !important;
  border-bottom: 1px solid $default-color !important;
}
input.white ~ label {
  color: $default-color !important;
}

input.form-control.white {
  color: $default-color !important;
}

// Radio
input[type='radio'] {
  opacity: 0 !important;
  position: absolute;
  z-index: -1;
}

// Phone
input[type='phone'] {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.form-check-input[type='radio'] ~ .form-check-label::before {
  background-color: white;
  border: 1px solid $secondary-color;
  border-radius: 50%;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  content: '';
  display: block;
  font-size: 16px;
  font-weight: 300;
  height: 16px;
  left: -24px;
  line-height: 24px;
  pointer-events: none;
  position: absolute;
  text-align: left;
  top: 4px;
  transition-delay: 0s, 0s, 0s;
  transition-duration: 0.15s, 0.15s, 0.15s;
  transition-property: background-color, border-color, box-shadow;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out;
  width: 16px;
}

.form-check-input[type='radio']:checked ~ .form-check-label::after {
  background-attachment: scroll;
  background-clip: border-box;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FCEB26'/%3e%3c/svg%3e");
  background-origin: padding-box;
  background-position: 50% 50%;
  background-position-x: 50%;
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  box-sizing: border-box;
  color: rgb(255, 37, 41);
  content: '';
  display: block;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
  height: 16px;
  left: -24px;
  line-height: 24px;
  position: absolute;
  text-align: left;
  top: 4px;
  width: 16px;
}

.form-check-input[type='radio']:disabled ~ .form-check-label::before {
  border-color: $secondary-color-light;
}

.form-check-input[type='radio']:invalid ~ .form-check-label::before {
  border-color: $danger-color;
}

// Checkbox
.form-check-input[type='checkbox'] + label {
  margin-left: 8px;
}

[type='checkbox']:checked,
[type='checkbox']:not(:checked) {
  position: absolute;
  opacity: 0;
}
.form-check-input[type='checkbox'].filled-in:not(:checked) + label::before,
label.btn input[type='checkbox'].filled-in:not(:checked) + label::before {
  border: 3px solid rgba(0, 0, 0, 0);
  border-radius: 1px;
  box-sizing: border-box;
  color: rgb(33, 37, 41);
  content: '';
  cursor: pointer;
  line-height: 25px;
  margin-top: 3px;
  position: absolute;
  text-align: left;
  top: 10px;
  transform: matrix(0.798635, 0.601815, -0.601815, 0.798635, 0, 0);
  transform-origin: 6px 6px;
  transition-delay: 0s, 0s, 0.1s, 0.1s, 0.1s, 0.1s;
  transition-duration: 0.25s, 0.25s, 0.2s, 0.2s, 0.2s, 0.2s;
  transition-property: border, background-color, width, height, top, left;
  transition-timing-function: ease, ease, ease, ease, ease, ease;
  width: 6px;
  z-index: 1;
}
.form-check-input[type='checkbox'].filled-in:not(:checked) + label::after,
label.btn input[type='checkbox'].filled-in:not(:checked) + label::after {
  background-color: rgba(0, 0, 0, 0);
  border: rgb(90, 90, 90) 2px solid;
  border-radius: 2px;
  box-sizing: border-box;
  color: rgb(33, 37, 41);
  content: '';
  cursor: pointer;
  height: 20px;
  left: 0px;
  line-height: 25px;
  position: absolute;
  text-align: left;
  top: 0px;
  transition-delay: 0s, 0s, 0.1s, 0.1s, 0.1s, 0.1s;
  transition-duration: 0.25s, 0.25s, 0.2s, 0.2s, 0.2s, 0.2s;
  transition-property: border, background-color, width, height, top, left;
  transition-timing-function: ease, ease, ease, ease, ease, ease;
  width: 20px;
  z-index: 0;
}
.form-check-input[type='checkbox'].filled-in:checked + label::before {
  backface-visibility: hidden;
  border: rgb(252, 235, 38) 2px solid;
  border-top: none;
  border-left: none;
  border-bottom-color: rgb(252, 235, 38);
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgba(0, 0, 0, 0);
  border-left-style: solid;
  border-left-width: 2px;
  border-right-color: rgb(252, 235, 38);
  border-right-style: solid;
  border-right-width: 2px;
  border-top-color: rgba(0, 0, 0, 0);
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-top-style: solid;
  border-top-width: 2px;
  box-sizing: border-box;
  color: rgb(33, 37, 41);
  content: '';
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
  height: 13px;
  left: 1px;
  line-height: 25px;
  margin-top: 3px;
  position: absolute;
  text-align: left;
  top: 0px;
  transform: matrix(0.798635, 0.601815, -0.601815, 0.798635, 0, 0);
  transform-origin: 8px 13px;
  transition-delay: 0s, 0s, 0.1s, 0.1s, 0.1s, 0.1s;
  transition-duration: 0.25s, 0.25s, 0.2s, 0.2s, 0.2s, 0.2s;
  transition-property: border, background-color, width, height, top, left;
  transition-timing-function: ease, ease, ease, ease, ease, ease;
  width: 8px;
  z-index: 1;
}
.form-check-input[type='checkbox'].filled-in:checked + label::after {
  background-color: $secondary-color;
  border-bottom-color: $secondary-color;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: $secondary-color;
  border-left-style: solid;
  border-left-width: 2px;
  border-right-color: $secondary-color;
  border-right-style: solid;
  border-right-width: 2px;
  border-top-color: $secondary-color;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-top-style: solid;
  border-top-width: 2px;
  box-sizing: border-box;
  color: rgb(33, 37, 41);
  content: '';
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
  height: 20px;
  left: 0px;
  line-height: 25px;
  position: absolute;
  text-align: left;
  top: 0px;
  transition-delay: 0s, 0s, 0.1s, 0.1s, 0.1s, 0.1s;
  transition-duration: 0.25s, 0.25s, 0.2s, 0.2s, 0.2s, 0.2s;
  transition-property: border, background-color, width, height, top, left;
  transition-timing-function: ease, ease, ease, ease, ease, ease;
  width: 20px;
  z-index: 0;
}
.form-check-input[type='checkbox'].filled-in:invalid + label::after {
  background-color: $secondary-color;
  border-bottom-color: $danger-color;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: $danger-color;
  border-left-style: solid;
  border-left-width: 2px;
  border-right-color: $danger-color;
  border-right-style: solid;
  border-right-width: 2px;
  border-top-color: $danger-color;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-top-style: solid;
  border-top-width: 2px;
  box-sizing: border-box;
  color: rgb(33, 37, 41);
  content: '';
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
  height: 20px;
  left: 0px;
  line-height: 25px;
  position: absolute;
  text-align: left;
  top: 0px;
  transition-delay: 0s, 0s, 0.1s, 0.1s, 0.1s, 0.1s;
  transition-duration: 0.25s, 0.25s, 0.2s, 0.2s, 0.2s, 0.2s;
  transition-property: border, background-color, width, height, top, left;
  transition-timing-function: ease, ease, ease, ease, ease, ease;
  width: 20px;
  z-index: 0;
}
.form-check-input[type='checkbox'].filled-in:disabled + label::after {
  background-color: $secondary-color;
  border-bottom-color: $secondary-color;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: $secondary-color;
  border-left-style: solid;
  border-left-width: 2px;
  border-right-color: $secondary-color;
  border-right-style: solid;
  border-right-width: 2px;
  border-top-color: $secondary-color;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-top-style: solid;
  border-top-width: 2px;
  box-sizing: border-box;
  color: rgb(33, 37, 41);
  content: '';
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
  height: 20px;
  left: 0px;
  line-height: 25px;
  position: absolute;
  text-align: left;
  top: 0px;
  transition-delay: 0s, 0s, 0.1s, 0.1s, 0.1s, 0.1s;
  transition-duration: 0.25s, 0.25s, 0.2s, 0.2s, 0.2s, 0.2s;
  transition-property: border, background-color, width, height, top, left;
  transition-timing-function: ease, ease, ease, ease, ease, ease;
  width: 20px;
  z-index: 0;
}

// Switch
.bs-switch {
  box-sizing: border-box;
  color: rgb(33, 37, 41);
  display: block;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  min-height: 24px;
  padding-left: 36px;
  position: relative;
  text-align: left;

  label {
    box-sizing: border-box;
    color: rgb(33, 37, 41);
    display: inline-block;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 0px;
    position: relative;
    text-align: left;
    vertical-align: top;
  }
}

// Switch
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  border-color: $secondary-color;
  background-color: $default-color;
}
.custom-switch .custom-control-input ~ .custom-control-label::after {
  background-color: $secondary-color;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  border-color: $secondary-color;
  background-color: $secondary-color;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: $primary-color;
}
.was-validated .custom-control-input:valid:disabled ~ .custom-control-label::before {
  border-color: $secondary-color-light;
  background-color: $secondary-color-light;
}
.custom-switch .custom-control-input:disabled ~ .custom-control-label::after {
  background-color: $secondary-color-light;
}
.was-validated .custom-control-input:valid:invalid ~ .custom-control-label::before {
  border-color: $danger-color;
  background-color: $danger-color;
}
.custom-switch .custom-control-input:invalid ~ .custom-control-label::after {
  background-color: $danger-color;
}

.was-validated .custom-select:valid,
.was-validated .form-control:valid,
.was-validated .form-check-input:valid ~ .form-check-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  border-color: $secondary-color;
  background-image: none;
  color: $secondary-color;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.was-validated .custom-select:valid:focus {
  box-shadow: none;
  border-color: $secondary-color;
}

input.white {
  color: $secondary-color !important;
  background-color: transparent !important;
}
input.white ~ label {
  color: white !important;
}

input.invisibleLabel ~ label {
  color: transparent !important;
  width: 0;
  margin: 0 !important;
}

.filterList {
  padding-left: 0px;

  .form-check {
    width: 100%;
  }
}

.form-check-input[type='checkbox'] {
  z-index: 3;
  margin-top: 0rem;
  width: 1.25rem;
}
.form-check-input[type='checkbox'].labelBefore {
  width: 100%;
}
.form-check-input[type='checkbox'].labelBefore.filled-in:not(:checked) + label,
.form-check-input[type='checkbox'].labelBefore.filled-in:checked + label {
  display: block;
  width: 100%;
}

.form-check-input[type='checkbox'].labelBefore.filled-in:not(:checked) + label::after,
.form-check-input[type='checkbox'].labelBefore.filled-in:checked + label::after {
  left: unset;
  right: 0;
}
.form-check-input[type='checkbox'].labelBefore.filled-in:checked + label::before {
  left: unset;
  right: 10px;
}

.white.form-check-input[type='checkbox'].filled-in:not(:checked) + label::after {
  border-color: white;
}

.white.form-check-input[type='checkbox'].filled-in:checked + label::after {
  border-color: white !important;
}

// style pour le changement de couleur des placeholder dans la refonte SearchPassenger
.input-searchPassenger-refonte {
  max-width: 20vw;
  border: 0;
  background-color: #101526;
  text-align: center;
  color: white;
}

.input-searchPassenger-refonte::placeholder {
  color: white;
}

.input-searchPassenger-refonte-active {
  width: 100px;
  position: relative;
  top: -1px;
  height: 60px;
  border: 4px solid #101526;
  border-radius: 20px;
  background-color: white;
  text-align: center;
  color: black;
  opacity: 100%;
  animation: input-down 0.5s ease-in-out 1;
}

.input-searchPassenger-refonte-active-popUp {
  width: 100px;
  position: relative;
  top: -1px;
  height: 60px;
  border: 4px solid #101526;
  border-radius: 20px;
  background-color: white;
  text-align: center;
  color: black;
  opacity: 100%;
  animation: input-down-popUp 0.5s ease-in-out 1;
}

// Style pour la div qui contient l'input
.container-input {
  position: relative;
  height: auto;
  width: 100%;
}

.dropdown-div {
  width: 96.5%;
  position: absolute;
  z-index: 10;
  top: 12px;
  left: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  border: 4px solid #101526;
  background-color: white;
  border-radius: 25px;
  padding: 20px;
  padding-top: 70px;
  animation: dropDown-down 0.5s ease-in-out 1;
}

// Style pour l'input
.dropdown-input {
  width: 80%;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid black;
  border-top-left-radius: 18px;
}

.input-name {
  position: relative;
  z-index: 100000;
}

.choise-div {
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.choise-div-popUp {
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.choise-p {
  margin: 0;
  color: gray;
}

.choise-arrow {
  width: 10vw;
  height: 3vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gainsboro;
  color: gray;
  transform: rotate(180deg);
  margin-left: 10px;
  border-radius: 5px;
}

.close-div {
  width: 95%;
  position: absolute;
  z-index: 2;
  top: 12px;
  left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  border: 4px solid #101526;
  background-color: white;
  border-radius: 25px;
  padding: 6px;
  padding-top: 0px;
  animation: dropDown-up 0.4s ease-in-out 1;
}
.close-div-popUp {
  width: 95%;
  position: absolute;
  z-index: 2;
  top: 14px;
  left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  border: 4px solid #101526;
  background-color: white;
  border-radius: 25px;
  padding: 6px;
  padding-top: 0px;
  animation: dropDown-up 0.5s ease-in-out 1;
}

.form-check-label {
  font-size: 12px;
}

// ! Espace keyframes
@keyframes dropDown-down {
  from {
    padding: 6px;
    padding-top: 0;
  }

  to {
    padding: 20px;
    padding-top: 70px;
  }
}

@keyframes dropDown-up {
  from {
    padding: 20px;
    padding-top: 70px;
  }

  to {
    padding: 6px;
    padding-top: 0;
  }
}

@keyframes input-down {
  from {
    opacity: 0%;
    width: 70px;
  }

  to {
    opacity: 100%;
    width: 100px;
  }
}

@keyframes input-down-popUp {
  from {
    opacity: 0%;
    width: 70px;
  }

  to {
    opacity: 100%;
    width: 60px;
  }
}

.btn-dropdown-details {
  position: relative;
  z-index: 2000000;
}

.input-flight {
  border-bottom: 2px solid black !important;
}

.dropdown {
  width: 120px;
}

.bottom-footer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  bottom: 0;
  //width: 100%;
}

.content-pop-up-dashboard {
  position: relative;
  height: 572px;
  overflow: auto;
}

.content-pop-up-mobile {
  height: 94.2vh;
  overflow: scroll;
  padding-bottom: 100px;
}

.content-pop-up {
  overflow: auto;
  padding-top: 39px !important;
}

.MuiGrid-grid-xs-5 {
  width: 170px !important;
}
.MuiGrid-justify-xs-center {
  justify-content: space-between !important;
}
