.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: $secondary-color;
  border: 1px double $secondary-color;
  color: $primary-color;
}

.CalendarDay__selected_span {
  background: $light-gray;
  border: 1px double $light-gray;
  color: $default-color;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before,
.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid $secondary-color;
}
.DayPickerKeyboardShortcuts_showSpan {
  color: $primary-color;
}

.SingleDatePicker {
  &.SingleDatePicker_1 {
    padding: 0;
    width: 100%;

    .SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__withBorder.SingleDatePickerInput__withBorder_2,
    .DateInput.DateInput_1 {
      padding: 0;
    }

    .SingleDatePickerInput__withBorder {
      border: none;
    }
    .SingleDatePickerInput,
    .DateInput,
    input {
      width: 100%;
      font-family: $roboto-font-family;
      font-size: 16px;
      padding: 9.6px 0 6.4px 0;
    }
  }
}
