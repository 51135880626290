.Dashboard {
  height: 100vh;
  font-size: 0.9rem;
  font-style: normal;

  .Title {
    font-family: $roboto-font-family;
    font-weight: 600;
    text-transform: uppercase;
    font-style: italic;
    font-size: 23px;
    color: $black !important;
    margin: 0;
    padding: 3px 6px;
    background-color: #f3f2f3;
    border-radius: 0;

    .p {
      font-size: 1.3rem !important;
    }
  }

  .Title:hover {
    box-shadow: none;
  }
  .Title:active {
    box-shadow: none !important;
  }

  .badgeStats {
    background-color: $black;
    border-radius: 4px;
    color: white;
    font-family: $font-family-monospace;
    font-style: normal;
    padding: 3px 10px;
    font-size: 1.2rem;

    .yellowData {
      color: $primary-color;
      font-family: $roboto-font-family;
      font-weight: 600;
      text-transform: uppercase;
      font-style: italic;
    }
  }

  .DashboardBadge {
    font-weight: 700;
    line-height: 24px;
    padding: 0px 10px 0px 7px;
    font-size: 16px !important;
    margin-left: 10px;
  }

  .DashboardDetails {
    border-radius: 40px !important ;
    overflow: auto;
    background-color: #ffffff;
    color: #000000;
    position: -webkit-sticky;
    position: sticky;
    width: 400px;
    min-height: 100%;

    .Icon {
      color: #f0f0f0;
    }

    .DateAndTime {
      text-align: center;

      .DayRideDetails {
        font-family: $font-family-monospace;
        font-weight: 400;
        font-size: 1.5rem;
        font-style: normal !important;
      }

      .HourRideDetails {
        font-family: $roboto-font-family;
        font-weight: 600;
        font-size: 1.5rem;
        text-transform: uppercase;
        font-style: italic;
      }
    }

    .Informations {
      margin-top: 1em;

      .Ride {
        font-style: italic;
        font-weight: bold;
        display: flex;
        flex: wrap;
        width: auto;
        height: 25px;
        margin: 0 10px;
        border-radius: 5px;
        padding: 3px 16px 7px 16px !important;
        margin-bottom: 10px;
        background: $secondary-color;
        color: $primary-color;

        .Info {
          font-style: normal;
          font-weight: normal;
          color: $default-color;
        }
      }
    }

    .Passenger {
      background-color: #f3f2f3;
      border-radius: 20px !important;
      text-align: center;
      padding: 1em;
      margin-top: 2em;
      height: 100%;

      .Names {
        display: flex;
        flex-direction: column;
      }

      .More {
        padding-top: 2em;
        width: auto;
        text-align: start;
        font-weight: bolder;
        font-size: 1.4em;

        .MoreInformations {
          font-weight: normal;
          font-size: small;
          color: #707077;
        }
      }
    }

    .Reservation {
      margin-top: 1em;
      text-align: end;

      .ReservationNumber {
        font-family: $roboto-font-family;
        font-weight: 600;
        font-size: 1.5rem;
        text-transform: uppercase;
        font-style: italic;
      }

      .ReservationThe {
        font-family: $font-family-monospace;
        color: #707077;
      }
    }

    .Rate {
      display: flex;
      flex-direction: column;

      .NoMarginBottom {
        margin-bottom: 0px !important;
      }

      .Price {
        background-color: #f3f2f3;
        border-radius: 20px !important;
        padding-bottom: 30px;
        margin-bottom: -30px;

        .MarginRightImportant {
          margin-right: auto !important;
        }

        span {
          input {
            padding-right: 12px;
            padding-top: 0px;
            margin-left: 50%;
            text-align: right;
            border-bottom: 2px solid;
          }
        }

       /*  span::after {
          position: absolute;
          right: 2px;
          bottom: 17px;
          font-size: 16px;
          content: '€';
        } */

        .Buttons {
          z-index: 4;
        }
      }
    }

    .DetailsMap {
      border-radius: 20px !important;
      overflow: hidden;
      position: -webkit-sticky;
      position: sticky;
      top: 0px;
      width: 100%;
      height: 100%;
      max-height: 530px;
    }
  }

  .DashboardMap {
    border-radius: 20px !important ;
    overflow: hidden;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    width: 100%;
    height: 100%;
  }

  .UnactivatedMap {
    background: url('../../img/mapBackground.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    height: 100%;
  }

  .ContentMask{
    height: 100%;
    width: 100%;
  }

  .ContentParent{
    height: 100%;
    width: 100%;
    position: absolute;
    display: grid;
    align-items: center;
    justify-content: center;
    width:100%;
  }

  .blurMask{
    background: url('../../img/mapBackground.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    height: 100%;
    width: 100%;
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
  }

  .WaitingRides {
    //font-size: 0.9rem;
    font-style: normal;
    width: 100%;
    padding: 0px 10px 20px 10px;

    .row {
      margin: 0;
    }
  }

  .TravelCardOperator {
    margin-top: 10px !important;
    margin-bottom: 25px !important;
    max-width: 100vw !important;
    border-radius: 10px !important;
    background: #f3f2f3 !important;
    box-shadow: 5px 5px 10px #d1d0d1, -5px -5px 10px #ffffff !important;
  }

  .TitleTravelCard {
    font-family: $roboto-font-family;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    font-style: italic;
  }

  .TextTravelCard {
    font-family: $font-family-monospace;
    font-weight: 400;
    font-size: 16px;
    font-style: normal !important;
  }
  .DriverBadge {
    background-color: #e7e6e7;
    padding: 4px 16px;
    border-radius: 20px;
  }

  .FirstLineAddress {
    font-weight: 700;
    font-size: 14px;
  }

  .hidden {
    display: none;
  }

  .filtersDrawer {
    bottom: 60px;
  }
}
