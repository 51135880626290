// --------------------------------------------------------------
// MOBILE STYLE
// --------------------------------------------------------------

.d-xs-none {
  display: none;
}

a {
  color: $secondary-color;
}

a:hover {
  color: $light-gray;
}

// operator
.cancelBtn {
  height: 35px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 8px;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px !important;
  font-family: $font-family-monospace !important;
}

// dropdown create ride
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.5em 2em;
  font-size: 0.8em;
}

.BottomButtons {
  background-color: #e7e6e7;
  border-radius: 10px;
  display: inline-block;
  position: relative;
  max-height: 5rem;
  min-width: 20%;
  margin-top: -30px !important;
  bottom: -45px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  box-shadow: $z-depth-1;
  max-width: fit-content;

  .col-xs-3 {
    margin: auto;
    display: flex;
    align-items: center;
  }

  .col-xs-6 {
    margin: auto;
    display: flex;
    align-items: center;
  }

  .btn {
    padding: 0.84rem 0rem;
    width: 8rem;
    overflow-x: hidden;
    border-radius: 0px;
  }
}

#no-mt {
  .md-form {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 1.2rem;
  }

  h2 {
    font-size: 1.1rem;
  }

  p {
    font-size: 0.8rem;
  }

  .fontSize09rem {
    font-size: 0.9rem;
  }

  // Manager
  .mobileFullHeader {
    width: 100%;
    margin-top: 60px;
    padding: 0 !important;
  }

  .mobilePadding {
    padding: 4% !important;
  }

  .createRideHeaderMobile {
    margin-top: -4px !important;

    .row {
      margin: 0;
    }
  }

  .passengerProfileHeaderMobile {
    margin-top: -4px !important;

    .row {
      margin: 0;
    }
  }

  .passengerFavoritesHeaderMobile {
    margin-top: -4px !important;

    .row {
      margin: 0;
    }
  }

  .operatorProfileHeaderMobile {
    margin-top: -4px !important;

    .row {
      margin: 0;
    }
  }
}

@media screen and (max-width: 500px) {
  h2 {
    font-size: 0.9rem;
  }

  .BottomButtons {
    min-width: 60%;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: -30px !important;
    max-width: calc(100vw - 17px);
    
    .col {
      padding: 0px !important;
    }

    .btn {
      font-size: 0.5rem;
      padding: 0.2rem !important;
      border-radius: 0px !important;
      width: 6rem;
    }
  }
  .smaller-btn {
    min-width: 100% !important;

    .btn {
      width: 4.2rem !important;
    }
  }
}

// Toggle button style
.toggle {
  width: 100%;
  height: 45px;
  border: 2px solid $secondary-color;
  cursor: pointer;
  transition: 0.4s ease;
}

.toggleOn {
  width: 50%;
  height: 100%;
  background-color: $secondary-color;
  color: $primary-color;
  border-radius: 24px;
}

.toggleOff {
  width: 50%;
  height: 100%;
}

// PASSENGER -------------------

.smTravelCard {
  width: 95vw;
  border-radius: 5px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 3px -2px rgba(0, 0, 0, 0.08), 0 3px 4px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  padding: 16px 8px 16px 8px;

  .Time {
    font-family: $roboto-font-family;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    font-style: italic;
  }

  .Date {
    font-family: $font-family-monospace;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    display: inherit;
    text-align: right;
  }
}

.barDrawerParent {
  width: 100%;
  height: 13px;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 13px;
}

.barDrawer {
  width: 60px;
  height: 3px;
  // margin-top:10px !important;
  border-radius: 5px;
  display: block;
  background-color: $secondary-color;
  margin: auto;
}

.blockDrawer {
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: $default-color;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.drawerWrapper {
  width: 100%;
  position: absolute;
  bottom: 130px;
  left: 0;
  background-color: $default-color;
  overflow: hidden;
  transition: 0.3s ease;
}

.drawerWrapperForm {
  width: 100% !important;
  left: 0;
  background-color: $default-color;
  overflow: hidden;
  display: none;
  transition: 0.3s ease;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.DrawerText {
  font-size: 14px;
}

.DrawerTitle {
  font-size: 1.65em;
  font-weight: 600;
}

.DrawerTitleItalic {
  font-size: 1.65em;
  font-weight: 600;
  font-family: $roboto-font-family;
  font-style: italic;
  text-transform: uppercase;
}

.DrawerSupportPhone {
  font-family: $font-family-monospace;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  // padding: 20px 0px;
  text-align: center;
  color: $black;
}

.DrawerButton {
  margin: 0px 0px;
  padding: 9px 3px;
  border-radius: 6px;
}

.subbtn {
  //vertical-align: middle !important;
  //border: 2px solid $secondary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  padding: 4px;
  text-align: center;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 5px 5px 13px #c2c2c2, -5px -5px 13px #ffffff;
}

.InfoCard {
  min-width: 90vw;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 3px -2px rgba(0, 0, 0, 0.08), 0 3px 4px 0 rgba(0, 0, 0, 0.06);
  padding: 20px 10px;
  border-radius: 5px;
  font-family: $font-family-monospace;
  font-weight: 400;

  .LicensePlate {
    border: 3px solid $extra-light-gray;
    border-radius: 5px;
    padding: 0px 6px;
    font-size: 14px !important;
  }

  .Title {
    font-family: $roboto-font-family !important;
    text-transform: uppercase;
    font-size: 16px !important;
    font-weight: 600 !important;
    font-style: italic;
    color: $black;
  }

  .Text {
    font-size: 14px !important;
    margin-bottom: 0px !important;
    margin-top: 5px;
  }
}

// Select service passenger
.selectServiceBody {
  max-height: 80vh;
  margin-top: 120px !important;
}

.selectServiceBodyChild {
  padding-bottom: 80px !important;
}

.bg-transparent {
  background-color: transparent;
}
.bg-grey {
  background-color: $extra-light-gray;
}

// Navbar Mobile globale
.navBar {
  height: 60px;
  width: 100%;
  top: 0;
}

.sideBarWrapper {
  position: fixed;
  transition: 0.4s ease;
  top: 0;
  left: 0;
  z-index: 1079;
  padding: 0 0 15px;
  font-size: 16px;

  a {
    color: #000;
  }

  &.oppened {
    width: 100%;
  }

  .backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #545055;
    opacity: 0.5;
    -webkit-transition: opacity 0.4s linear;
    -o-transition: opacity 0.4s linear;
    transition: opacity 0.4s linear;
  }

  .sideBar {
    margin-left: 0;
    width: 100%;
    position: absolute;
    top: 0;
    transition: 0.4s ease;
    z-index: 1080;
    background-color: $extra-light-gray;
    overflow-y: scroll;
    padding-bottom: 30px !important;
    color: $black;

    .SidebarButton {
      margin: 15px 0px 0px 0px;
      padding: 10px;
      border-radius: 10px;
      box-shadow: 5px 5px 10px #d1d0d1, -5px -5px 10px #ffffff !important;
      font-family: $font-family-monospace;
      font-weight: 400;
      display: flex;
      flex-direction: column;

      i {
        font-size: 1.3em;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .sideBar {
      min-width: 100px;
      max-width: 300px !important;
    }
  }

  .horizontalDividerSecondary {
    height: 1px;
    background: $secondary-color;
  }
}
