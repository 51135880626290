.CustomBooking {
  width: 100%;
  margin-right: 0;
  margin-left: 0;

  .MuiFormControl-root{
    padding-top: 20px;
  }
}

.CustomBookingBottomPage {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  padding-top: 30px;
  //position: relative;
  bottom: 0;

  .col-2, .col-3, .col-4{
    flex-direction: column;
  }

  .infoRow{
    justify-content: center;
    margin: 0;

    p{
      font-size: 1.5rem
    }
  }

  .iconRow{
    margin: 0;
    justify-content: center;
    align-items: center;

    i{
        font-size: 1.3rem;
    }
    p{
      font-size: 1.2rem;
      padding-top:16px;
      margin: 0; 
    }
  }
}

.CustomBookingTitle{
  justify-content: center;
  //margin-top: 3%;
  margin-bottom: 50px;
  margin-left: 0; 
  margin-right: 0;
}

.CustomBookingSubTitle{
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 0; 
  margin-right: 0;
}

.CustomBookingTopPage{
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.CustomBookingAddress{
  width: 70%;
  position: relative;
  margin: 0;
  border-radius: 35px;
  height: 80px;
  //margin-top: 10%;
  margin-left: auto;
  margin-right: auto;


  .FormInput{
    background-color: white;
    height: 60px;
    margin: 10px;
    border-radius: 50px;
    padding-bottom: 10px;
  }

  .md-form{
    margin: 0 !important;
  }
}

.serviceCard{
  justify-content: center;
  border-radius: 10px;
  padding: 20px;
  margin: 0;
  width: 45%
}

@media screen and (max-width: 768px){
  .CustomBookingTitle{
    margin-bottom: 15px;

    h1{
      font-size: 1.6rem !important;
    }
  }

  //margin-top for CustomBookingTitle
  .withBG {
    margin-top: 10% !important;
  }
  .withoutBG {
    margin-top: 30% !important;
  }
  

  .CustomBookingSubTitle{
    padding: 20px;
    h4{
      font-size: 1rem !important;
    }
  }

  .CustomBookingBottomPage{
    position: relative !important;
    p{
      font-size: 0.9rem !important;
    }
    img{
      height: inherit;
      max-width: 40vw;
    }
  }

  .CustomBookingAddress{
    width: 75%;
    height: auto;
    flex-direction: column;
    padding: 5px 5px 15px 5px;
    align-items: center;

    .FormInput{
      max-width: 90%;
    }
  }

  .serviceCard{
    width: 85%;
  }
  .showDetail{
    width: 100% !important;
  }
}