.CallCardModal {
  width: 500px !important;
  height: 812px !important;
  background-color: $extra-light-gray;
  .modal-content {
    height: 100%;
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.CallCard {
  width: 100%;
  height: 100%;
  max-width: 100%;
  background-color: white;
  border-radius: 10px;
  overflow: auto !important;

  .Icon {
    width: 50px;
    max-width: 50px;
    color: rgb(252, 238, 38);
    text-align: center;
    padding-top: 26px;
  }

  .InfosCall {
    width: 150px;
    max-width: 150px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgb(243, 242, 243);
    padding: 0.5rem;

    .Action {
      color: rgb(243, 242, 243);
    }

    .Name {
      color: rgb(252, 238, 38);
      max-width: 100%;
      max-height: 20px;
      word-break: break-all;
    }
  }

  .Btn {
    width: 100px;
    max-width: 100px;
    padding: 0;

    .TopBtn {
      height: 37.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgb(243, 242, 243);
      border-bottom: 1px solid rgb(243, 242, 243);
    }

    .BottomBtn {
      height: 37.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgb(243, 242, 243);
    }
  }
}

// Pose problème pour le filtre date mobile
// .row {
//   margin-left: 0;
// }

.CallCardNewRide {
  height: 75px;
  max-height: 75px;
  width: 300px;
  max-width: 300px;
  background-color: rgb(16, 21, 38);
  border-radius: 10px 10px 0 0;

  .Icon {
    color: rgb(252, 238, 38);
    text-align: center;
    padding-top: 26px;
    width: 50px;
    max-width: 50px;
    height: 75px;
    max-height: 75px;
  }

  .InfosCall {
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgb(243, 242, 243);
    width: 150px;
    max-width: 150px;
    height: 75px;
    max-height: 75px;
    padding: 0.5rem;

    .Action {
      color: rgb(243, 242, 243);
    }

    .Name {
      color: rgb(252, 238, 38);
      max-width: 100%;
      max-height: 20px;
      word-break: break-all;
    }
  }

  .Btn {
    padding: 0;
    width: 100px;
    max-width: 150px;
    height: 75px;
    max-height: 75px;
    color: rgb(243, 242, 243);

    .TopBtn {
      height: 37.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid rgb(243, 242, 243);
    }

    .BottomBtn {
      height: 37.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgb(243, 242, 243);
    }
  }

  .Address {
    width: 300px;
    max-width: 300px;
    //height: 65px;
    max-height: 60px;
    background-color: rgb(255, 255, 255);
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid grey;
    overflow: hidden;
    padding: 0px;

    .FirstLineAddress {
      height: 65px;
      font-size: medium;
    }

    .Selected {
      padding: 20px 0;
      text-align: right;
    }
  }

  .Booking {
    width: 300px;
    max-width: 300px;
    background-color: rgb(255, 255, 255);
    justify-content: center;
    align-items: center;

    div {
      display: flex;
      flex-direction: column;

      &.active {
        background: $secondary-color;
        color: $primary-color;
      }
    }
  }

  .Service {
    width: 300px;
    max-width: 300px;
    background-color: rgb(255, 255, 255);
    //padding: 1rem .3rem;

    .btn {
      padding: 0.5rem 0.8rem 0.5rem 0.8rem;
    }
  }

  .Add {
    width: 300px;
    max-width: 300px;
    height: 65px;
    max-height: 65px;
    background-color: rgb(255, 255, 255);

    div {
      display: block;

      &.md-form {
        margin-top: 0;
      }
    }
  }

  .Next {
    height: 75px;
    max-height: 75px;
    width: 300px;
    max-width: 300px;
    background-color: rgb(16, 21, 38);
    color: rgb(243, 242, 243);
    border-radius: 0 0 10px 10px;
    justify-content: center;
    align-items: center;
    text-align: center;

    .Left {
      margin-bottom: 8px;
      width: 150px;
      max-width: 150px;

      .MoreDetails {
        color: rgb(243, 242, 243);
      }
    }

    .Right {
      margin-bottom: 8px;
      padding: 0;
      width: 150px;
      max-width: 150px;
    }
  }
}
