.Medical {
  width: 100%;
  margin-right: 0;
  margin-left: 0;

  .Assured {
    display: flex;
    flex-direction: column;

    .Info {
      margin: 5px 0;
      padding: 0 8px;
      background: $btn-gray;
      border-radius: 5px;
    }
  }

  .NewAssured {
    display: flex;
    flex-direction: column;
  }

  .Care {
    display: flex;
    flex-direction: column;

    .Info {
      margin: 5px 0;
      padding: 0 8px;
      background: $btn-gray;
      border-radius: 5px;
    }
  }
}