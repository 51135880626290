.Operator--Rides {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  font-family: $font-family-monospace;
  font-weight: 400;
  font-size: 12px;

  .TravelCardOperator {
    margin-top: 10px !important;
    margin-bottom: 25px !important;
    max-width: 100vw !important;
    border-radius: 10px !important;
    background: #f3f2f3 !important;
    box-shadow: 5px 5px 10px #d1d0d1, -5px -5px 10px #ffffff !important;
  }

  .TitleTravelCard {
    font-family: $roboto-font-family;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    font-style: italic;
  }

  .TextTravelCard {
    font-family: $font-family-monospace;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
  }

  .DriverBadge {
    background-color: #e7e6e7;
    padding: 4px 16px;
    border-radius: 20px;
  }

  .FirstLineAddress {
    font-weight: 700;
    font-size: 14px;
  }

  .hidden {
    display: none;
  }

  .title_home{
    font-size: 1.65em;
    font-weight: 600;
    font-family: $roboto-font-family;
    font-style: italic;
    text-transform: uppercase;
    padding-bottom: 5px;
    //position: absolute;
    overflow: visible;
  }

  .Favorites{
    margin:0;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 70px;
    padding-bottom: 30px;
    font-size: 16px;
    overflow-x: auto;
    flex-direction: column;
    width: 100%;
    
    .ScrollingHorizontal{
     // padding-left: 10px;
      //white-space: nowrap;
      //padding-top: 45px;
    
    .Favorite{
      margin: 3% !important;
      padding: 10px;
      width: 44% !important;
      height: 90px !important;
      overflow-x: hidden;
      border-radius: 10px !important;
      background: #f3f2f3 !important;
      box-shadow: 5px 5px 10px #d1d0d1, -5px -5px 10px #ffffff !important;
      //display: flex;
      //align-items: stretch;
      //justify-content: flex-start;
      display: inline-block;
      float: none;

      .logo{
        width: 32px;
        height: 32px;
        border-radius: 5px;
      }
    }
  }  
  }
  .Statistic{
    margin: 8px !important;
    padding: 10px;
    width: 50% !important;
    height: 50px !important;
    border-radius: 10px !important;
    background: #f3f2f3 !important;
    box-shadow: 5px 5px 10px #d1d0d1, -5px -5px 10px #ffffff !important;
    display: flex;
    align-items: center;
  }
  .filtersDrawer {
    bottom: 115px;
  }

  .BottomFilters {
    background-color: #fff;
    position: fixed;
    width: 100%;
    //height: 60px;
    bottom: 50px;
    //bottom: 0px;
    //box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 3px -2px rgba(0, 0, 0, 0.08), 0 3px 4px 0 rgba(0, 0, 0, 0.06);
    text-align: center;
    font-size: 16px;
    padding-top: 10px;
    //padding-bottom: 10px;
    z-index: 100;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    
    .Col {
      margin: 10px 2px;
      padding: 0;
    }

    .btn-secondary {
      margin: 0px;
      padding: 0px;
    }

    .createNewRide {
      border-radius: 50%;
      height: 60px;
      width: 60px;
      position: absolute;
      top: -30px;
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .horizontalDividerSecondary{
    height: 1px;
    background: #e7e6e7;
    margin: 5px auto 5px auto;
    width: 90%;
  }

  // .Container {
  //   margin-top: 66px;
  //   width: 100%;
  // position: relative;

  .toggle {
    margin: 0 16px;
    width: calc(100% - 32px);
    position: absolute;
    top: 66px;
    font-family: $roboto-font-family;
    font-weight: 600;
    font-size: 15px !important;
    font-style: italic;
    text-transform: uppercase;

    .waitingRidesQuantity {
      color: $white !important;
      font-family: $font-family-monospace;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      padding: 0px 8px;
      font-size: 16px;
    }

    .toggleOn {
      .waitingRidesQuantity {
        background: $white !important;
        color: $black !important;
      }
    }
    @media screen and (max-width: 768px) {
      p {
        font-size: 15px;
      }
    }
  }

  .NextRides {
    width: 100%;
    padding: 126px 16px 100px 16px;

    .row {
      margin: 0;
      // height: 160px;

      // .position-absolute {
      //   top: unset;
      //   bottom: 16px;
      // }

      .dropdown-menu {
        left: -30px !important;
      }
    }
  }

  .WaitingRides {
    margin: 0;
    display: flex;
    flex-direction: row;
    overflow: auto;
    position: absolute;
    bottom: 130px;
    z-index: 100;
    width: 100%;
    overflow: auto;
    padding: 0 16px 16px 16px;

    .WaitingRide {
      background-color: #2d3140;
      height: 240px;
      margin-top: 0 !important;
      padding: 10px 10px 10px 10px;
      border-radius: 10px;
      font-size: 12px;
    }

    .FistLineWaitingCardAddress {
      font-family: $font-family-monospace;
      font-weight: 700;
      color: $white;
      font-size: 14px;
    }

    .SecondLineWaitingCardAddress {
      font-family: $font-family-monospace;
      font-weight: 400;
      color: $white;
      font-size: 12px;
    }

    .row {
      margin: 0;
      width: 315px !important;
      margin: 0 8px;
      border-radius: 5px;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 16px;
      }

      .btn-rectangular {
        margin: 20px 5px 5px 5px !important;
        padding: 10px 16px !important;
        display: flex !important;
        flex-direction: row;
        align-content: space-around;
        border: none;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 3px -2px rgba(0, 0, 0, 0.08), 0 3px 4px 0 rgba(0, 0, 0, 0.06);
      }
    }
  }
  // }
}

@media screen and (max-width: 280px) {
  .informationRightBadgePadding {
    font-size: 10px !important;
    padding-left: 10px !important;
    padding-top: 2px !important;
    padding-right: 5px !important;
    padding-bottom: 2px !important;
  }
}
