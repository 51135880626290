.register-signin {

  .left {
    align-items: center;
    display: flex;

    a.nav-link {
      padding: 0;
      color: $black;
      font-family: $font-family-monospace;
    }

    h2 {
      font-family: $roboto-font-family;
      font-weight: 600;
      font-style: italic;
      text-transform: uppercase;
    }

    .largeBottomSpacing {
      padding: 0 0 58px 0;
    }

    label {
      font-size: 16px;
    }
  }

  .right {
    background: url('../../img/fill2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;
    height: 100vh;
  }
}

// Desktop
@media screen and (min-width: 576px) { 
  .register-signin {
    .logo {
      margin: 74px 0 42px 0;
    }

    h2 {
      padding: 0 0 58px 0;
    }

    .left {
      height: 100vh;

      .text-right {
        margin-top: 1rem;
      }
    }
  }
}


// Mobile
@media screen and (max-width: 576px) { 
  .register-signin { 

    a.nav-link {
      margin-top: 26px;
    }

    .logo {
      margin: 37px 0 82px 0;
    }

    h2 {
      padding: 0 0 25px 0;
    }

    .left {
      text-align: center;

      .largeBottomSpacing {
        padding: 0;
      }

      .buttonContainer {
        display: flex;
        justify-content: center;
        padding-top: 62px;
      }

      .signin {
        padding-bottom: 46px;
      }

      .text-right {
        text-align: left !important;
      }
    }
  }
}
