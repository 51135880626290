
.btn {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  box-shadow: none;
  transition: 0.4s ease;
  text-transform: none;
  &:hover{
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.08), 0 6px 10px 0 rgba(0, 0, 0, 0.06);
  }

  &-rectangular {
    border-radius: 5px !important;
    border: 1px solid $secondary-color;
  }

  &-checkBtn{
    height: 80px;
  }

  &-tertiary {
    border: 1px solid $default-color;
    color: $default-color;

    &:hover {
      background-color: $default-color;
    }
  }

  &-tertiaryMobile {
    border: 1px solid $default-color;
    color: $default-color;
    font-size: 0.5rem !important;
    &:hover {
      background-color: $default-color;
    }
  }

  &-noShadowBtn {
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
    &:visited {
      box-shadow: none;
    }
    &:focus {
      box-shadow: none;
    }
  }

  &-noShadowBtnNoBackground {
    box-shadow: none !important;
    background-color: transparent !important;
    &:hover {
      box-shadow: none;
    }
    &:visited {
      box-shadow: none;
    }
    &:focus {
      box-shadow: none;
    }
  }

  &-reset-uppercase {
    text-transform: initial;
  }
}

@media screen and (max-width: 320px) { 
  
  .btn {
    &-rectangular {
      max-height: 48px;
    }

    &-yellowBtn {
      font-size: 0.8rem !important;
    }
  }
}



// Round buttons
.roundBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  transition: 0.4s ease;
  &:hover{
    box-shadow: none;
  }

  &-rectangular {
    border-radius: 5px !important;
    border: 1px solid $secondary-color;
  }

  &-carre {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 4px;
  }

  i{
    font-size:30px;
  }
}

.roundedUnchecked {
  border: 2px solid $grey-color !important;
  z-index: 2;
}

.roundedChecked {
  width: 22px;
  height: 22px;
  border: 2px solid $secondary-color !important;
  z-index: 2;
}

button.grey {
  background: $btn-gray !important;
}