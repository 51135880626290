// Dropdowns
.dropdown {
  .dropdown-menu {
    .dropdown-item {
      &:active {
        background-color: $grey-darken-1;
      }
      &.active {
        background-color: $secondary-color;
        color: $primary-color;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .dropdown{
    .squared-dropdown-menu{
      right:-60px !important;
      left:auto !important;
    }
  }
}
