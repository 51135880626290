.Passenger--Ride {
  .ChangeDG {
    width: 100%;
  
    .Header {
      background: $secondary-color;
      padding: 76px 16px 22px 16px;
      width: 100%;
      color: $white;
      font-size: 14px;
  
      h3 {
        color: $primary-color;
        text-transform: uppercase;
        font-style: italic;
        font-weight: 600;
        font-size: 18px;
        text-align: center;
      }
    } 
  
    .Cards {
      margin-bottom: 96px !important;
  
      .Card {
        margin: 24px 16px 0 16px;
        background: $white;
        border-radius: 5px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.4); 
        display: flex;
        flex-direction: column;
        padding: 8px 8px 12px 16px;
        
        .Picto {
          width: 100%;
          text-align: right;
        }
        .Brand {
          font-weight: 600;
          font-style: italic;
          font-size: 18px;
          text-transform: uppercase;
        }
        .Description {
          padding-top: 12px;
          font-family: $font-family-monospace;
          font-size: 14px;
        }
        .Phone {
          text-align: right;
          font-size: 14px;
          font-style: italic;
          font-weight: 600;
          padding-top: 16px;
          padding-right: 8px;
        }
      }
    }
  
    .BottomButton {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: $white;
      height: 80px;
      display: flex;
      justify-content: center;
      padding: 16px;
  
      button {
        font-weight: 600;
        font-size: 16px; 
        padding: 0;
      }
    }
  }
}