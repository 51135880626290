.DriverGroup {
  //padding-top: 74px;
  color:$black;
  padding-bottom: 30px;

  .TravelCardOperator {
    margin-top: 10px !important;
    margin-bottom: 25px !important;
    margin-left: 10px;
    margin-right: 10px;
    width: 320px !important;
    border-radius: 10px !important;
    background: #f3f2f3 !important;
    box-shadow: 5px 5px 10px #d1d0d1, -5px -5px 10px #ffffff !important;
  }

  input {
    border-bottom: 1px solid $light-gray !important;
  }

  .Label {
    font-family: $font-family-monospace !important;
  } 

  h3 {
    font-weight: 600; 
    text-transform: uppercase;
    font-style: italic;
    font-family: $roboto-font-family;
  }


  .Brand {
    background: $white;
    padding: 0 35% 0 7%;
    display: flex;
    flex-direction: row;
    // margin-bottom: 56px;

    .Logo {
      margin-top: 16px;
      min-width: 180px;
      min-height: 180px;
      -webkit-max-logical-width:180px;
      -webkit-max-logical-height:180px;
      background: $white;
      border-radius: 10px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 3px -2px rgba(0, 0, 0, 0.08), 0 3px 4px 0 rgba(0, 0, 0, 0.06);
      margin-bottom: -32px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .Details {
      margin-top:16px;
      padding-left: 34px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      div {
        display: inline;
      }
      
      .h1{
        font-family: $roboto-font-family;
        font-weight: 600;
        font-style: italic;
        text-transform :uppercase;
        color: $secondary-color;
      }

      h4 {
        font-size: 16px;
        font-family: $font-family-monospace !important;
        font-style: normal;
        text-transform: none;
        font-weight: 400;
        margin-bottom: 8px;
      }
      p {
        color: $light-gray;
        font-family: $font-family-monospace;
      }
      h5{
        color:white;
        font-family: $font-family-monospace;
        font-weight: 400;
        display:none;
      }
    }
  }

  .Nav {
    height: 56px;
    background: $secondary-color;
    // color: $default-color;
    //padding: 0 40%;
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    padding: 0 calc(39% + 64px) 0 0;
    justify-content: flex-end;
    font-family:$roboto-font-family;
    text-transform: uppercase;
    font-style: italic;
  }

  h4{
    font-family:$roboto-font-family;
    text-transform: uppercase;
    font-style: italic;
    font-weight: 600; 
    font-size:16px;
    //padding: 10x 16px 10px 16px;
  }
  .Card {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 3px -2px rgba(0, 0, 0, 0.08), 0 3px 4px 0 rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    background: $white;
    margin: 0 calc(39% + 64px) 24px 16%;
    padding: 32px;
  }

  .Description {
    .Text {
      margin-top: 18px;
      margin-bottom: 30px;
      font-family: $font-family-monospace;
    }

    .Contacts {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;

      .Contact {
        //flex: 0 50%;
        width: auto;
        margin: 0 10px;

        .Container {
          border-radius: 5px;
          padding: 5px 16px !important;
          margin-bottom: 10px;
          background: $btn-gray;

          a {
            margin-left: 12px;
            color: $black;
            font-family: $font-family-monospace;
          }
        }

        // &:nth-child(odd) {
        //   .Container {
        //     margin-right: 16px;
        //   }
        // }
        // &:nth-child(even) {
        //   .Container {
        //     margin-left: 16px;
        //   }
        // }
      }
    }
  }

  .Photos {
    display: flex;
    flex-direction: row;
    background: $extra-light-gray;
    background-color: white;
    padding: 0;

    .Photo {
      width: 33%;
      max-height:100%;
      align-self: center;

      &:nth-child(2) {
        width: 34%;
        padding: 0 6px 0 6px;
      }

      &:first-child {
        img {
          border-radius: 5px 0 0 5px;
        }
      }
      &:last-child {
        img {
          border-radius: 0 5px 5px 0;
        }
      }

      img {
        min-width: 100%;
        max-height: 100%;
        overflow:hidden;
      }
    }
  }

  .Services {
    .Service {
      margin-top: 22px;
      display: flex;
      flex-direction: row;
      border-bottom: solid 1px #f3f2f3;
      padding-bottom: 24px;
      margin-bottom: 24px;

      .Picto {
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .Content {
        display: flex;
        flex-direction: column;
        font-family: $font-family-monospace;

        h5 {
          font-weight: 700;
        }

        .Description {
          font-weight: 400 !important;
          font-style:normal !important;
          margin: 0;
          padding: 0;
          background: inherit;
          border: 0;
          box-shadow: unset;
        }
      }
    }

    .Infos {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;
      font-weight: 400;
      font-style:normal;
      font-family: $font-family-monospace;

      .Info {
        flex: 0 50%;
        width: 100%;

        .Container {
          border-radius: 5px;
          padding: 12px 16px;
          margin-bottom: 8px;
          background: $btn-gray;

          a {
            margin-left: 12px;
            color: $black;
            font-family: $font-family-monospace;
          }
        }

        &:first-child {
          .Container {
            margin-right: 16px;
          }
        }
        &:nth-child(even) {
          .Container {
            margin-left: 16px;
          }
        }
      }

    }
  }

  .FollowUs {
    .Links {
      display: flex;
      margin: 22px -8px 0 -8px;

      .Link {
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $extra-light-gray;
        border-radius: 5px;
        margin: 0 8px;
      }
    }
  }

  .Legal {
    margin-bottom: 136px;
    padding: 0;
    font-family: $font-family-monospace;

    .Button {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      padding: 0 16px;
    }
    .Content {
      padding: 16px;

      &.hidden {
        display: none;
      }
    }
  }

  .Booking {
    position: absolute;
    width: 27%;
    background: $white;
    border-radius: 5px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 3px -2px rgba(0, 0, 0, 0.08), 0 3px 4px 0 rgba(0, 0, 0, 0.06);
    right: 13%;
    top: 230px;
    padding-bottom: 24px;
    font-family: $font-family-monospace;
    z-index: 99;

    label {
      &.active {
        color: $black;
        transform: translateY(-16px); 
      }
    }

    .Header {
      display: flex;
      flex-direction: row;
      // font-size: 25px;

      div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 8px;
        background: $primary-color;//$extra-light-gray;
        font-family: $font-family-monospace;
        text-align: center;
        cursor: pointer;

        &:first-child {
          border-top-left-radius: 5px;
        }
        &:last-child {
          border-top-right-radius: 5px;
        }
        &:nth-child(2) {
          //to be replaced by 34 %
          width: 50%;
        }

        &.active {
          background: $secondary-color;
          color: $primary-color;
        }
      }
    }

    .Content {
      .User {
        padding: 32px;
        padding-top: 24px;
        border-bottom: 1px solid $extra-light-gray;
  
        .SignIn {
          position: relative;
          font-size: 14px;
          font-family: $font-family-monospace;
          color: $light-gray;
          border-bottom: 1px solid $extra-light-gray;
  
          p {
            margin-right: 130px;
          }
  
          button {
            position: absolute;
            top: 0;
            right: 0;
            width: 130px;
            padding: 10px !important;
          }
        }
      }

      .Address {
        position: relative;
        padding: 22px 25px;
        border-bottom: 1px solid $extra-light-gray;

        .StartDate,
        .ArrivalDate {
          //margin-top: 44px;
          //padding-top: 15px;
          display: flex;
          flex-direction: column;
          //border-top: 1px solid $extra-light-gray;
          // justify-content: space-between;
          &.hidden {
            display: none;
          }

          .Picto {
            position: absolute;
            right: 12px;
            top: 8px;
            cursor: pointer;
          }
        }

        .Passenger {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          //padding-top: 22px;
          padding-bottom: 22px;
          border-bottom: 1px solid $extra-light-gray;

          .Input-Group {
            display: flex;
            flex-direction: row;

            .Button {
              &.Left {
                padding-right: 12px;
              }
              &.Right {
                padding-left: 12px;
              }
            }
  
            input {
              width: 44px;
              border: 1px solid $light-gray !important;
              text-align: center;
              color: #495057;
            }
          }
          
        }
      }

      .Services {
        .Label {
          margin-top: 32px;
        }

        .List {
          margin-top: 16px;
          display: flex;

          button {
            position: relative;
            float: left;
            margin: 0 4px;
            padding: 5px 15px;
            text-transform: capitalize;
            font-size: 12px;
            font-family: $font-family-monospace;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            
            &:first-child {
              margin-left: 0;
            }
            &::last-child {
              margin-right: 0;
            }

            &.Service {
              background: $btn-gray !important;
              color: $black;
              box-shadow: unset;
              border: unset;

              &.active {
                color: $primary-color;
                background: $secondary-color !important;
              }
            }

            .Picto {
              margin: 0;
              padding: 0 0 0 3px;
              // margin-left: 8px;
            }
          }
        }
      }

      .MoreInformations {
        .Button {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          height: 48px;
          padding: 0 16px;
          border-bottom: 1px solid $extra-light-gray;
        }
        .Content {
          padding: 0 32px 32px 32px;
          border-bottom: 1px solid $extra-light-gray;
    
          &.hidden {
            display: none;
            border: 0;
          }
        }
      }

      .PriceAndCTA {
        padding: 16px;
        //border-top: 1px solid $extra-light-gray;
        //padding-top: 16px;
        
        .PriceBloc {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-bottom: 1px solid $extra-light-gray;
          padding: 10px;
          //margin-bottom: 24px;

          &.hidden {
            display: none;
          }
        }

        .ButtonsBloc {
          button {
            margin: 0;
            width: 100%;
            font-size: 16px;
            margin-bottom: 8px;
            text-transform: inherit;
          }
        }

      }
    }
  }
}

.BookingFrame{
  width: 100%;
  height: 100%;
  background-color: white;
  margin: 0;
  //overflow-y: scroll;

  .Booking {
    border-radius: 5px;
    padding-bottom: 24px;
    margin: 0;
    font-family: $font-family-monospace;

    label {
      &.active {
        color: $black;
        transform: translateY(-16px); 
      }
    }

    .Header {
      display: flex;
      flex-direction: row;
      // font-size: 25px;

      div {
        //to be replaced by 33 %
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 8px;
        background: $extra-light-gray;
        font-family: $font-family-monospace;
        text-align: center;
        cursor: pointer;

        &:first-child {
          border-top-left-radius: 5px;
        }
        &:last-child {
          border-top-right-radius: 5px;
        }
        &:nth-child(2) {
          //to be replaced by 34 %
          width: 50%;
        }

        &.active {
          background: $secondary-color;
          color: $primary-color;
        }
      }
    }

    .Content {
      margin: 0;
      
      .User {
        padding: 32px;
        padding-top: 24px;
        border-bottom: 1px solid $extra-light-gray;
  
        .SignIn {
          position: relative;
          font-size: 14px;
          font-family: $font-family-monospace;
          color: $light-gray;
          border-bottom: 1px solid $extra-light-gray;
  
          p {
            margin-right: 130px;
          }
  
          button {
            position: absolute;
            top: 0;
            right: 0;
            width: 130px;
            padding: 10px !important;
          }
        }
      }

      .Address {
        position: relative;
        padding: 32px;
        padding-top: 16px;
        border-bottom: 1px solid $extra-light-gray;

        .StartDate,
        .ArrivalDate {
          margin-top: 0px;
          padding-top: 0px;
          display: flex;
          flex-direction: column;
          //border-top: 1px solid $extra-light-gray;
          font-weight: 600;
          // justify-content: space-between;
          &.hidden {
            display: none;
          }

          .Picto {
            position: absolute;
            right: 12px;
            top: 8px;
            cursor: pointer;
          }
        }

        .Passenger {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-top: 22px;
          padding-bottom: 22px;
          border-bottom: 1px solid $extra-light-gray;

          .Input-Group {
            display: flex;
            flex-direction: row;

            .Button {
              &.Left {
                padding-right: 12px;
              }
              &.Right {
                padding-left: 12px;
              }
            }
  
            input {
              width: 44px;
              border: 1px solid $light-gray !important;
              text-align: center;
              color: #495057;
            }
          }
          
        }
      

      .Services {
        .Label {
          margin-top: 32px;
        }

        .List {
          margin-top: 16px;
          display: flex;

          button {
            position: relative;
            float: left;
            margin: 0 4px;
            padding: 5px 15px;
            text-transform: capitalize;
            font-size: 12px;
            font-family: $font-family-monospace;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            
            &:first-child {
              margin-left: 0;
            }
            &::last-child {
              margin-right: 0;
            }

            &.Service {
              background: $btn-gray !important;
              color: $black;
              box-shadow: unset;
              border: unset;
              vertical-align: middle;

              &.active {
                color: $primary-color;
                background: $secondary-color !important;
              }
            }

            .Picto {
              margin: 0;
              padding: 0 0 0 3px;
              // margin-left: 8px;
            }
          }
        }
      }
    }
      .MoreInformations {
        .Button {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          height: 48px;
          padding: 0 16px;
          border-bottom: 1px solid $extra-light-gray;
        }
        .Content {
          padding: 0 32px 32px 32px;
          border-bottom: 1px solid $extra-light-gray;
    
          &.hidden {
            display: none;
            border: 0;
          }
        }
      }

      .PriceAndCTA {
        padding: 16px;
        border-top: 1px solid $extra-light-gray;

        .PriceBloc {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-bottom: 1px solid $extra-light-gray;
          padding: 10px;
          //margin-bottom: 24px;

          &.hidden {
            display: none;
          }
        }

        .ButtonsBloc {
          button {
            margin: 0;
            width: 100%;
            font-size: 16px;
            margin-bottom: 8px;
            text-transform: inherit;
          }
        }

      }
    }
}
}


// MOBILE
@media screen and (max-width: 768px) {
  .DriverGroup {
    //padding-top: 56px;
    display: flex;
    flex-direction: column;

    .Brand {
      order: 1;
      background: $secondary-color;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 0 60px 0;
      margin: 0;

      .Logo {
        width: 64px;
        height: 64px;
        margin: 12px 0;
        padding: 4px;
        min-width: 64px;
        min-height: 64px;
        -webkit-max-logical-width: 64px;
        -webkit-max-logical-height: 64px;
      }
      .Details {
        padding: 0;
        
        div {
          display: block;
          text-align: center;
          margin: 0px !important;
        }

        .cardTitlePadding{
          color: $default-color;
        }

        .h1 {
          color: $primary-color;
        }
        h4 {
          margin-top: 1rem;
          color: $white;
          text-align: center;
        }
        p {
          color: $white;
          text-align: center;
        }
        h5{
          display:inline;
        }
      }
    }

    .Nav {
      display: none;
    }

    .Booking {
      order: 2;
      box-shadow: unset;
      width: 100%;
      background: unset;
      position: unset;
      top: unset;
      margin-top: -45px;
      z-index: 2;

      

      .Header {
        margin: 0 16px;
        border-radius: 5px;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 3px -2px rgba(0, 0, 0, 0.08), 0 3px 4px 0 rgba(0, 0, 0, 0.06);
        // font-size: 30px;

        div{
          &:first-child{
            border-bottom-left-radius: 5px;
          }
          &:last-child{
            border-bottom-right-radius: 5px;
          }
        }
      }

      .Content {
        background: $white;
        margin-top: -32px;
        padding-top: 24px;

        &.hidden {
          display: none;
        }
      }
    }

    .Card {
      margin: 8px 16px;
    }

    .Description {
      order: 3;

      .Contacts {
        .Contact {
          //margin: auto -10%;
          flex: 100%;
          .Container {
            margin: 0 0 8px 0 !important;
            overflow: auto;
            white-space: nowrap;
          }
          &:last-child{
            .Container {
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }

    .Photos {
      order: 4;
      display: flex;
      flex-direction: column;
      background: $extra-light-gray;
      padding: 0;
  
      .Photo {
        width: 70%;
        max-height: 100%;
  
        &:nth-child(2) {
          width: 70%;
          padding: 6px 0 6px 0;
        }
  
        &:first-child {
          img {
            border-radius: 5px 5px 0 0;
          }
        }
        &:last-child {
          img {
            border-radius: 0 0 5px 5px;
          }
        }
  
        img {
          min-width: 100%;
          max-height: 100%;
          overflow:hidden;
          object-fit: cover;
        }
      }
    }

    .Services {
      order: 5;

      .Infos {
        .Info {
          flex: 100%;
          .Container {
            margin: 0 0 8px 0 !important;
          }
          &:last-child{
            .Container {
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }
    
    .FollowUs {
      order: 6;

      .Links {
        display: flex;
        flex-wrap: wrap;

        .Link {
          // flex: 1 20%;
          flex: 0 20%;
          width: 100%;
          margin: 8px;
        }
      }
    }

    .Legal    
    {
      order: 7;

      .Button {
        padding: 16px;
        height: auto;
      }
    }

  }
}
