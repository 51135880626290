.PassengerFavorites {
  width: 100%;
  margin-right: 0;
  margin-left: 0;

  .FavoritesCardPassenger{
    margin-top:10px !important;
    margin-bottom:25px !important;
    max-width: 100vw !important;
    border-radius: 10px !important;
    background: rgb(16, 21, 38);
    box-shadow:  5px 5px 10px #d1d0d1, 
                 -5px -5px 10px #ffffff!important;
  }

  .TitleFavoritesCard{
    font-family: $roboto-font-family;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    color: Yellow;
  }

  .TextFavoritesCard{
    font-family: $font-family-monospace;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    color: White;
  }

  .Favorite {
    width: 100%;
    padding: 12px 16px 70px 16px;

    .row {
      margin: 0;

    }
  }

  .border-secondary {
    border: 2px solid $light-gray !important;
    border-radius: 5px !important;
  }
  
}