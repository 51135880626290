.Title {
  font-family: $roboto-font-family;
  font-weight: 600;
  text-transform: uppercase;
  font-style: italic;
  font-size: 30px;
  line-height: 1.7;
  color: $primary-color;
}

.Header {
  .Title {
    color: $black;
  }
}


.text {
  color: $black;
  size: 16px;
  font-family: $font-family-monospace;

}

.top10 {

  margin-top: 0px;
}

table.responsive {
  min-height: 400px;
}

table {
  font-family: $font-family-monospace;
  font-size: 14px !important;

}

th {
  font-weight: 700 !important;
  white-space: nowrap;
}

td {
  //overflow-x: hidden !important;
  padding: 10px 10px !important;
}

.table_rides {

  td:nth-child(1) {
    width: 100px;
  }

  td:nth-child(2) {
    width: 120px;
  }

  td:nth-child(3) {
    // width: 370px;
    width: auto;
  }

  td:nth-child(4) {
    width: auto;
  }

  td:nth-child(5) {
    width: 100px;
  }

  td:nth-child(6) {
    width: 70px;
    white-space: nowrap;
  }

  td:nth-child(7) {
    width: 80px;
  }

  td:nth-child(8) {
    width: 20px;
  }

  // tr:hover{
  //   background-color: $btn-gray;
  // }
}

.table_finance {
  td:nth-child(1) {
    width: 150px;
  }

  td:nth-child(2) {
    width: 140px;
  }

  td:nth-child(3) {
    width: 70px;
  }

  td:nth-child(4) {
    width: 40px;
  }

  td:nth-child(5) {
    width: 70px;
  }

  td:nth-child(6) {
    width: 40px;
  }

  td:nth-child(7) {
    width: auto;
  }

  td:nth-child(8) {
    width: 170px;
  }

  td:nth-child(9) {
    width: 100px;
  }

  .md-form {
    margin-top: 0rem;
  }
}

.table_margin {
  td:nth-child(1) {
    width: 180px;
  }

  td:nth-child(2) {
    width: 240px;
  }

  td:nth-child(3) {
    width: 70px;
  }

  td:nth-child(4) {
    width: 40px;
  }

  td:nth-child(5) {
    width: 70px;
  }

  td:nth-child(6) {
    width: 40px;
  }

  td:nth-child(7) {
    width: auto;
  }

  td:nth-child(8) {
    width: 150px;
  }

  .md-form {
    margin-top: 0rem;
  }
}

.table_businessGroup {
  td {
    padding: 4px 4px !important;
  }

  td:nth-child(1) {
    width: 270px;
  }

  td:nth-child(2) {
    width: 650px;
  }

  // tr:hover{
  //   background-color: $btn-gray;
  // }
}

.table_invoices {
  td {
    padding: 10px 10px !important;
  }

  td:nth-child(1) {
    width: 100px;
  }

  td:nth-child(2) {
    width: 150px;
  }

  td:nth-child(3) {
     //width: 150px;
    width: auto;
  }

  td:nth-child(4) {
    // width: 350px;
    width: auto;
    //white-space: nowrap;
  }

  td:nth-child(5) {
    width: 70px;
  }

  td:nth-child(6) {
    width: 95px;
  }

  td:nth-child(7) {
    width: 20px;
  }

  td:nth-child(8) {
    width: 20px;
  }

  td:nth-child(9) {
    width: 20px;
  }

  td:nth-child(10) {
    width: 20px;
  }

  // tr:hover{
  //   background-color: $btn-gray;
  // }
}

.table_catalogue {
  td {
    padding: 10px 10px !important;
  }

  td:nth-child(1) {
    width: auto;
  }

  td:nth-child(2) {
    width: 200px;
  }

  td:nth-child(3) {
    width: 200px;
  }

  td:nth-child(4) {
    width: 120px;
  }

  td:nth-child(5) {
    width: 120px;
  }

  td:nth-child(6) {
    width: 120px;
  }
}

.table_invoices_add {
  td {
    padding: 10px 10px !important;
  }

  td:nth-child(1) {
    width: 50px;
  }

  td:nth-child(2) {
    width: 180px;
  }

  td:nth-child(3) {
    width: 180px;
  }

  td:nth-child(4) {
    width: 450px;
  }

  td:nth-child(5) {
    width: 450px;
  }

  td:nth-child(6) {
    width: 120px;
  }

  // tr:hover{
  //   background-color: $btn-gray;
  // }
}

.table_quotations {
  td {
    padding: 10px 10px !important;
  }

  td:nth-child(1) {
    width: 120px;
  }

  td:nth-child(2) {
    width: auto;
  }

  td:nth-child(3) {
    width: auto;
  }

  td:nth-child(4) {
    width: 90px;
  }

  td:nth-child(5) {
    width: 90px;
  }

  td:nth-child(6) {
    width: 120px;
  }

  td:nth-child(7) {
    width: 50px;
  }

  td:nth-child(8) {
    width: 50px;
  }

  // tr:hover{
  //   background-color: $btn-gray;
  // }
}

.table_missions {
  th:nth-child(4) {
    text-align: center;
  }

  th:nth-child(5) {
    text-align: center;
  }

  th:nth-child(6) {
    text-align: center;
  }

  td:nth-child(1) {
    width: 180px;
  }

  td:nth-child(2) {
    width: auto;
  }

  td:nth-child(3) {
    // width: 370px;
    width: auto;
  }

  td:nth-child(4) {
    width: 100px;
    text-align: center;
  }

  td:nth-child(5) {
    width: 100px;
    text-align: center;
  }

  td:nth-child(6) {
    width: 100px;
    text-align: center;
  }

  td:nth-child(7) {
    width: 50px;
  }

  // tr:hover{
  //   background-color: $btn-gray;
  // }
}

.Quotations {
  .Header {
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 25px;
      font-weight: 600;
    }

    .subtitle{
      font-size: 12px;
      font-style: italic;
      color:#878488;
    }

    .md-form {
      margin-top: 8px !important;
    }
  }

  .subHeader {
    .md-form {
      margin-top: 0px !important;
    }
  }

  
}
.TableHeader {
  th {
    font-size: 16px;
    background-color: #878488;
    color: white;
    border-bottom: none !important;
  }

  th:nth-child(1) {
    width: 1%;
  }
  
  th:nth-child(2) {
    width: 60%;
  }

  th:nth-child(3) {
    width: 8%;
  }

  th:nth-child(4) {
    width: 8%;
  }

  th:nth-child(5) {
    width: 12%;
  }

  th:nth-child(6) {
    width: 8%;
  }

  th:nth-child(7) {
    width: 1%;
  }

  td {
    border-bottom: 2px dashed #dee2e6;
    border-top: none;
  }
}

.PaddingFilter {
  padding-top: 6px !important;
  padding-right: 16px !important;
  padding-bottom: 4px;
  padding-left: 16px !important;

  input {
    cursor: pointer;
  }
}

.mdForm-margin {
  .md-form {
    margin: 0.5rem 0 0 0.5rem;
  }

  i {
    color: #878488;
  }
}

.DatePickerFilter {
  padding-bottom: 12px !important;
}


.DatePicker {
  font-family: $font-family-monospace !important;
  font-weight: 400 !important;
  color: $black;
  max-width: 250px;
}