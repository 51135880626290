.Passenger--Ride {
  .PassengerRideDetails {
    width: 100%;

    .Header {
      background: $secondary-color;
      padding: 76px 16px 22px 16px;
      width: 100%;
      color: $white;
      font-size: 14px;

      h3 {
        color: $primary-color;
        text-transform: uppercase;
        font-style: italic;
        font-weight: 600;
        font-size: 18px;
      }
    } 

    .Card {
      margin: 24px 16px 0 16px;
      background: $white;
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      display: flex;

      &:last-child {
        margin-bottom: 24px;
      }

      &.AddressAndDate {
        .Pictos {
          display: flex;
          flex-direction: column;
          margin: 16px 0;
          width: 24px;
          padding: 12px 0;
          background: $secondary-color;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }

        .Address {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 24px 16px;
          width: 70%;

          .departure_address,
          .arrival_address {
            color: $light-gray;
            font-size:12px;
            &::first-line {
              font-weight: 700;
              color: $black;
              font-size:14px;
            } 
          }

          .HorizontalLine {
            height: 2px;
            background: $extra-light-gray;
            width: 100%;
          }
        }

        .Date {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          padding: 0 8px 0 0;
          justify-content: center;
          width: calc(100% - (70% + 24px));

          .Day {
            font-family: $font-family-monospace;
            font-size: 16px;
          }
          .Time {
            font-size: 18px;
            font-weight: 600;
          }

          .Duration,
          .Service {
            font-family: $font-family-monospace;
            font-size: 12px;
          }
        }
      }

      &.Details,
      &.Comment,
      &.Promotion {
        padding: 16px;
        display: flex;
        flex-direction: column;

        .Input {
          background: $btn-gray;
          padding: 12px;
          border-radius: 5px;
          width: 100%;
          font-family: $font-family-monospace;
          font-size: 14px;
          margin-bottom: 8px;

          .Picto {
            margin-right: 12px;
          }
        }

        .Row {
          display: flex;
          flex-direction: row;
          .Left {
            margin-right: 4px;
          }
          .Right {
            margin-left: 4px;
          }
          .Left,
          .Right {
            width: 50%;
            .Input {
              width: 100%;
            }
          }
        }
      }
      
      &.Comment,
      &.Promotion {
        font-family: $font-family-monospace;
        font-size: 14px;
      }
    }
  }
}