.Chat {
  width: 100vw;
  position: fixed;
  bottom: -1px;
  left: 0;
  background-color: white;
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
  margin: 0px;

  .ChatBottom {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 0;
  }

  .drawerChatMessage {
    width: 100% !important;
    left: 0;
    background-color: white;
    overflow: hidden;
    display: none;
    transition: 0.3s ease;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    margin: 0;
    padding: 0.25rem;
  }

  .Icon {
    width: 50px;
    max-width: 50px;
    font-size: 24px;
    text-align: center;
    margin: 13px 0px;
    padding: 10px 0px;
    color: $secondary-color;
    vertical-align: middle;
  }

  .Icon:active{
    color: $primary-color;
    background-color: $secondary-color;
    border-radius: 25%;
  }

  .active{
    color: $primary-color;
    background-color: $secondary-color;
    border-radius: 25%;
  }
}

.title_chatinput {
  font-size: 17px;
  margin-top: 0.5rem;
  display: block;
  padding-top: 0.5rem;
}

.ContainerInput {
  font-family: $font-family-monospace;
  font-size: 17px;
  //width: 90%;
  position: relative;
  min-height: 8vh;
  padding-top: 0.5rem;
  display: flex;
  align-items: center;

  .Icon {
    width: 50px;
    max-width: 50px;
    font-size: 24px;
    text-align: center;
    padding-top: 23px;
    color: $secondary-color;
  }

  .Input {
    background-color: $secondary-color;
    color: white;
    border-radius: 20px;
    border: none;
    width: 100%;
    height: 40px;
    padding: 5px 35px 5px 15px;
    //position: absolute;
  }
}

.w-90_100 {
  width: 90%;
}

.w-90vw {
  width: 90vw;
}

.IconInput {
  //position: absolute;
  z-index: 100;
  //margin-left:87%;
  //margin-right:3%;
  margin-left: -32px;
  color: $primary-color;
}

.horizontalDividerSecondary {
  height: 1px;
  background: #e7e6e7;
  margin-top: 10px;
  width: 90%;
}

.userIcon {
  text-transform: uppercase;
  font-family: $font-family-monospace;
  font-weight: 700;
  border-radius: 50%;
  color: $secondary-color;
  text-align: center;
  font-size: 20px;
  padding: auto;
  margin: auto 0;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary-color;

  .selected {
    background-color: #a9d18e;
  }
}

.conv {
  margin: 0.25rem 1rem 0.25rem 1rem;
  border-bottom: 1px solid #ffffff;
  height: 70px;
  font-size: 16px;
  width: 100%;

  .lastMessage {
    overflow-y: hidden;
    height: 65px;
    display: flex;
    flex-direction: column;
    padding-top: 4px;
  }
}

.contact {
  margin: 0.25rem 1rem 0.25rem 1rem;
  //  border-bottom: 1px solid #ffffff;
  height: 80px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.channel {
  margin-top: 1.5rem !important;
  display: flex;
  justify-content: center;

  .headerChannel {
    background-color: #ffffff;
    border-radius: 25px;
    height: 60px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 401;
    // margin: 60px 10px 40px 10px;

    i {
      padding: 0.7rem 0.7rem;
    }
  }

  .messageContainer {
    margin-top: 65px;
    margin-bottom: 10px;
    padding: 0px 1rem;
    width: 100%;
    max-height: 68vh;
  }
}

.messageList {
  max-height: 100%;
  overflow-y: scroll;

  .message {
    border-radius: 20px;
    min-height: 30px;
    background-color: #ffffff;
    margin: 5px 0px;
    padding: 8px 10px !important;
    max-width: fit-content; //80%;
    overflow-wrap: anywhere;
    white-space: pre-line;
  }

  .background-owner {
    background-color: $secondary-color !important;
    color: #ffffff;
  }

  .Align-flex-end {
    align-items: flex-end;
  }

  .text-bottom {
    margin-left: auto;
  }
}

.bottomChannel {
  background-color: #e7e6e7;
  width: 350px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 2px 10px rgba(255, 255, 255, 0.35), 2px -2px 10px rgba(255, 255, 255, 0.35),
    -2px 2px 10px rgba(255, 255, 255, 0.35), -2px -2px 10px rgba(255, 255, 255, 0.35);

  .feed {
    max-height: 500px;
    min-height: 100px;
    padding: 0px;
    overflow-y: scroll;
    //text-align: center;

    .row {
      padding-top: 5px !important;
    }
  }
}
.ChatDesktopModal {
  position: absolute;
  bottom: 95px;
  right: 15px;
  max-height: 600px;
  min-height: 100px;
  width: 350px;
  background-color: #e7e6e7;
  border-radius: 10px;
  overflow-y: scroll;
  box-shadow: 2px 2px 10px rgba(255, 255, 255, 0.35), 2px -2px 10px rgba(255, 255, 255, 0.35),
    -2px 2px 10px rgba(255, 255, 255, 0.35), -2px -2px 10px rgba(255, 255, 255, 0.35);
}
