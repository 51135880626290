.Payment {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: $white;
  z-index: 20;
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  padding-right: 16px;
  padding-bottom: 8px;
  padding-left: 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: $font-family-monospace;

  .Line {
    padding-top: 8px; 
    height: 3px;
    background: $white-base;
    border-radius: 3px;
    width: 16%;
    margin: 0 auto 10px;
  }

  .Title {
    font-family:$roboto-font-family;
    margin-bottom: 8px;
    font-style: italic;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    color:$black;
  }

  .Methods {
    .Method {
      // border-bottom: 1px solid $black;
      padding: 8px 0;

      .Header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid black;
        padding-bottom: 12px;

        .Name {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: center;
          align-items: center;
          font-family: $font-family-monospace;
          font-size: 16px;

          .Picto {
            margin-right: 0px;
          }
        }
      }

      form {
        display: flex;
        flex-direction: column;

        .Label {
          color: black;
          font-family: $font-family-monospace;
          font-size: 14px;
          padding-top: 12px;
          // padding: 12px 0;
          border-bottom: 2px solid $light-gray;
        }

        .TwoColumns {
          display: flex;
          flex-direction: row;

          .Label {
            width: 50%;

            &:nth-child(odd) {
              margin-right: 12px;
            }
            &::nth-child(even) {
              margin-left: 12px;
            }
          }
        }
      }

      .alignLeft {
        float: left;
        max-width: 80%;
      }

      .alignRight {
        float: right;
      }
    }
  }
}