.Business {
  background-color: #fff;

  .Header {
    height: 800px;
    // background: url('../../img/Business/header-business.png');
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;

    .navbar {
      padding: 0 16px !important;
    }

    .content {
      position: absolute;
      right:100px;
      padding-top: 280px;
      width: 800px;
      text-align: right;
      

      h1 {
        color: $white;
        font-family: $roboto-font-family;
        font-weight: 600;
        text-transform: uppercase;
        font-style: italic;
        font-size: 40px;
        margin: 0;
        line-height: 1.7;
      }

      h2 {
        font-family: $font-family-monospace;
        color: $white;
        font-size: 16px;
        margin-top: 36px;
        line-height: 1.6;
      }

      .btn {
        margin-top: 40px;
        height: 48px;
        width: 290px;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }

  .Header-city {
    height: 800px;
    // background: url('../../img/Business/header-city.png');
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;

    .navbar {
      padding: 0 16px !important;
    }

    .content {
      position: absolute;
      right:100px;
      padding-top: 280px;
      width: 800px;
      text-align: right;
      

      h1 {
        color: $white;
        font-family: $roboto-font-family;
        font-weight: 600;
        text-transform: uppercase;
        font-style: italic;
        font-size: 40px;
        margin: 0;
        line-height: 1.7;
      }

      h2 {
        font-family: $font-family-monospace;
        color: $white;
        font-size: 16px;
        margin-top: 36px;
        line-height: 1.6;
      }

      .btn {
        margin-top: 40px;
        height: 48px;
        width: 290px;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }

  .Comments {
    background-color: #fff;
    padding: 0 19%;
    display: flex;
    flex-direction: row;
    margin-bottom: 100px;
  
    .CommentContainer {
      position: relative;
      top: -72px;
      width: 50%;

      .Comment {
        border-radius: 10px;
        background: $white;
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.08), 0 6px 10px 0 rgba(0, 0, 0, 0.06);
        margin: 0 16px;
        padding: 32px;
    
        .type {
          background: $secondary-color;
          font-family: $roboto-font-family;
          color: $primary-color;
          border-radius: 12px;
          padding: 5px 15px;
          display: inline;
          text-transform: uppercase;
          font-weight: 600;
          font-style: italic;
        }
    
        .title {
          margin-top: 20px;
          font-family: $roboto-font-family;
          font-size: 30px;
          font-weight: 600;
          font-style: italic;
          text-transform :uppercase;
        }
    
        .introduction {
          font-family: $font-family-monospace;
          font-size: 16px;
        }
    
        .content {
          background: $secondary-color;
          border-radius: 5px;
          color: $white;
          padding: 24px;
          margin-top: 40px;
          font-family: $font-family-monospace;
          font-weight: 400;
    
          img { 
            margin-bottom: 18px;
            display: block; 
          }
    
          .author {
            font-weight: 700;
            margin-top: 14px;
          }
        }
      }
    }
  }

  .Advantages {
    background: rgb(243, 242, 243);
    margin-top: 200px;

    .img_center{
      width: 70%;
      text-align: center;
      border-radius: 10px 10px 10px 10px;
      box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.08), 0 6px 10px 0 rgba(0, 0, 0, 0.06);
      text-align: center;
      position: relative;
      top: -200px;
    }

    .Cards {
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
      margin-top: 56px;
      font-family: $font-family-monospace;
      position: relative;
      top: -150px;

      .Card {
        text-align: center;
        flex: 0 0 25%;
        max-width: 25%;
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;

        h5 {
          font-family: Titillium Web, sans-serif;
          font-size: 25px;
          font-weight: 800;
          font-style: italic;
          text-transform: uppercase;
          margin-top: 20px;
        }

        p {
          font-family: Source Sans Pro, sans-serif;
          font-size: 16px;
          margin-top: 15px;
        }
      }
    }
  }

  .Functions{
    background-color: #fff;

    .Card{
      padding-top: 100px;
      justify-content: center;

      .title{
        font-family: $roboto-font-family;
        font-style: italic;
        text-transform: uppercase;
        font-size: 16px;
        background: #101526;
        color: #fceb26;
        border-radius: 12px;
        height: 24px;
        padding: 2px 12px;
        display: inline;
      }

      p{
        font-family: $font-family-monospace;
        font-size: 16px;
        margin-top: 15px;
      }

      ul{
        list-style-image: url('../../img/Driver/BulletPoint.png');
        list-style-type: url('../../img/Driver/BulletPoint.png');
        padding-left: 20px;
        font-family: $font-family-monospace;
        font-style: normal;
      }

      li{
        margin-bottom: 20px;
      }
      
      img{
        width: 80%;
        text-align: center;
      }
    }
  }
  
  .Start-mobile{
    display:none;
  }

  .Start{
    margin-top:50px;
    margin-bottom: 80px;
    width: 80%;
    background-color: #101526;
    padding: 48px;
    border-radius: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title{
        font-family: Titillium Web, sans-serif;
        font-size: 40px;
        font-weight: 600;
        font-style: italic;
        text-transform: uppercase;
        color: #fff;
        text-align: left
    }

    p{
      color:#fff;
      font-family: Source Sans Pro, sans-serif;
      font-size: 16px;
      margin-top: 15px;
    }

    .btn {
      width: 208px;
      color: #0B060D;
      text-decoration: none;
    }
  }

  .Pricing{
    background-color: #F2F2F2;
    padding-top: 100px;
    padding-bottom: 80px;

    .info-mobile{
      display: none;
    }

    h2{
      font-family: Titillium Web, sans-serif;
      font-size: 40px;
      font-weight: 600;
      font-style: italic;
      text-transform: uppercase;
      color: #101526;
      text-align: center
    }

    p{
      font-family: Source Sans Pro, sans-serif;
      font-size: 16px;
      margin-top: 40px;
      padding-bottom: 40px;
      text-align: center
    }

    td{
      font-family: Source Sans Pro, sans-serif;
      font-size: 14px;
      text-align: center;
    }

    .first-column{
      text-align: left !important;
      padding: 20px 30px;

      .label{
        font-family: Source Sans Pro, sans-serif;
        font-size: 15px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.5;
        letter-spacing: normal;
        color: #0b060d;
      }

      .info{
        font-family: Source Sans Pro, sans-serif;
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #878488;
      }
    }

    .third-column{
      color:#fceb26 !important;
      border-bottom:1px solid #2d3140;
    }

    .title{
      font-family: Titillium Web, sans-serif;
      font-size: 20px;
      font-weight: 600;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: center;
      color: #0b060d;
      padding: 30px 0;
      border-bottom: none;
    }

    td{
      border-bottom: 1px solid #e7e6e7;
      line-height: 1;
    }

    .sub-section {
      td{
        border-bottom:none
      }
    }
    .prix{
      font-size: 30px !important;
      font-weight: bold;
    }
  }

  .Questions-mobile{
    display:none;
  }

  .Questions {
    background-image: url('../../img/Driver/Question.png');
    background-color: #101526; /* Used if the image is unavailable */
    height: auto; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */

    .content{
      padding-top: 80px;
      padding-bottom: 100px;
      margin-left: 19%;
      width: 688px
    }

    h1 {
      color: #fff;
      font-family: Titillium Web, sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      font-style: italic;
      font-size: 40px;
      margin: 0
    }

    h2 {
      font-family: Source Sans Pro, sans-serif;
      color: #fff;
      font-size: 16px;
      margin-top: 36px
    }

    .btn {
      margin-top: 40px;
      height: 48px;
      width: 258px;
      font-weight: 500;
      font-size: 16px
    }

    a {
      color: #0B060D;
      text-decoration: none;
    }
  }

}

// Medium
@media screen and (min-width: 768px) and (max-width: 992px) { 
  // .Driver {
  //   .Introduction {
  //     .Cards {
  //       margin-left: 24%;
  //     }
  //   }
  // }
}

// Mobile
@media screen and (max-width: 768px) { 
  .Business {
    background: $white;

    .Header {
      background: url('../../img/Driver/Mobile/Header.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom center;

      .navbar {
        padding: 0;

        .navbar-brand {
          margin: auto;
        }

        button.navbar-toggler {
          // position: fixed;
          // top: 9px;
        }
      }
      
      .content {
        width: 100%;
        right:unset;
        max-width: 100%;
        margin: 0;
        padding: 30px;
        align-items: center;
        text-align: center;
        margin-top:100px;
  
        .btn {
          margin-top: 40px;
          height: 48px;
          width: auto;
          font-weight: 500;
          font-size: 16px;
          margin-left:0px;
        }
      }
    }

    .Header-city {
      background: url('../../img/Driver/Mobile/Header.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom center;

      .navbar {
        padding: 0;

        .navbar-brand {
          margin: auto;
        }

        button.navbar-toggler {
          // position: fixed;
          // top: 9px;
        }
      }
      
      .content {
        width: 100%;
        right:unset;
        max-width: 100%;
        margin: 0;
        padding: 30px;
        align-items: center;
        text-align: center;
        margin-top:100px;
  
        .btn {
          margin-top: 40px;
          height: 48px;
          width: auto;
          font-weight: 500;
          font-size: 16px;
          margin-left:0px;
        }
      }
    }

    .Functions{
      display:none;
    }

    .Advantages{
      margin-top:100px;

      .img_center{
        width: 88%;
        top:-100px !important;
      }

      .Cards {
        display: flex;
        flex-wrap: unset;
        flex-direction: column;
        width:100%;

        .Card {
          min-height: unset;
          background: transparent;
          border: none;
          margin-bottom: 64px;
          max-width: 100%;

          
          .content {
            order: 2;
            text-align: center;
  
            span {
              margin: 0;
            }
          }

          .picto {
            order: 1;
          }
        }
      }
    }

    .Pricing{
      display:flex;
      flex-direction: column;
      width:100%;

      .info-mobile{
        display: block;
        text-align: center;
      }
      table{
        display:none !important;
      }

      .table-desktop{
        display: none;
      }
    }

    .Comments {
      padding: 0 16px;
      flex-direction: column;
    
      .CommentContainer {
        //margin-top:100px;
        top:-140px;
        width: 100%;

        .Comment {
          width:auto;
          margin: 0 16px 16px 16px;
        }
      }
    }

    .Start{
      display:none;
    }

    .Start-mobile{
      display: block;
      margin-top:50px;
      margin-bottom: 50px;
      width: 88%;
      background-color: #101526;
      padding: 25px;
      border-radius: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title{
        font-family: Titillium Web, sans-serif;
        font-size: 30px;
        font-weight: 600;
        font-style: italic;
        text-transform: uppercase;
        color: #fff;
        text-align: center
      }
    }


    .Questions{
      display:none;
    }

    .Questions-mobile{
      display:block;
      background-color: #101526; 
      height: auto; 
      line-height: 1.7;
      text-align:center;
      padding: 70px 30px;

      .content{
        width: 100%
      }
  
      h1 {
        color: #fff;
        font-family: $roboto-font-family;
        font-weight: 600;
        text-transform: uppercase;
        font-style: italic;
        font-size: 40px;
        margin: 0;
      }
  
      h2 {
        font-family: $font-family-monospace;
        color: #fff;
        font-size: 16px;
        margin-top: 36px
      }
  
      .btn {
        margin-top: 40px;
        height: 48px;
        width: 258px;
        font-weight: 500;
        font-size: 16px;
      }
  
      a {
        color: #0B060D;
        text-decoration: none;
      }


    }

  }
}


