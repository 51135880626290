.ToolBarItem {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 35px;
}

.ToolBarItem:hover {
  background-color: #101526;
  color: #FCEB36
}