//@import url('https://fonts.googleapis.com/css?family=Montserrat|Source+Sans+Pro|Titillium+Web&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,500,700|Source+Sans+Pro:400,700|Titillium+Web:600i&display=swap');



// Your custom variables

$default-color: rgb(255,255,255);
$primary-color: rgb(252, 235, 38);
$secondary-color: rgb(16, 21, 38);
$secondary-color-light: rgb(243, 242, 243);
$grey-color: rgba(55, 54, 56, 0.5);
$grey-divider: #393b46;
$success-color: rgb(84,145,17);
$danger-color: rgb(208, 2, 27);
$warning-color: rgb(255,130,13);
$special-color: rgb(76, 80, 93);

$black-base: rgb(252, 235, 38);
$white-base: rgb(16, 21, 38);

$white: #fff;
$black: #0b060d;
$light-black: rgba(84, 80, 85, 1);

$light-gray: rgba(135, 132, 136, 1);
$extra-light-gray: #f3f2f3;

$btn-gray: rgb(231, 230, 231);

$border-radius-base: 24px;

$roboto-font-family: 'Titillium Web', sans-serif;
$font-bold: 600;

$font-family-monospace: 'Source Sans Pro', sans-serif;
$font-bold: 400;

// LABEL: font-family: 'Montserrat', sans-serif;

// Formulaires
$input-md-focus-color: $secondary-color;
$input-success-color: $secondary-color;
$input-success-color: red;