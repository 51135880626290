.HomePage {

background-color: white;

  .Header {
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;

    .navbar {
      padding: 0 16px !important;
    }
  }
  .firstContent{
      padding-top: 133px;
      //margin-left: 19%;
      //width: 688px;
      width: 90%;
      margin-left:10%;
      margin-right:10%;

      #first_column{
        padding-right: 100px;
        padding-bottom: 1.5rem;
      }

      h1 {
        color: $secondary-color;
        //font-family: $roboto-font-family;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        //font-style: italic;
        font-size: 40px;
        margin: 0;
      }

      h4{
        color: $black;
        font-family: 'Montserrat', sans-serif;
        font-weight: 300;
        font-size: 18px;
      }

      .text-bottom{
        color:$special-color;
        font-family: $font-family-monospace;
      }
  }

  .cardRow{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    padding: 50px;
    background-image: url('../../img/Driver/Question.png');

    h2 {
      color: $secondary-color;
      //font-family: $roboto-font-family;
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      //font-style: italic;
      font-size: 26px;
      margin: 0;
    }

    h4{
      color: $black;
      font-family: 'Montserrat', sans-serif;
      font-weight: 300;
      font-size: 18px;
    }
}
.textRow{
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px;
  margin: 40px 0px;

  h2 {
    color: $secondary-color;
    //font-family: $roboto-font-family;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    //font-style: italic;
    font-size: 30px;
    margin: 0;
  }

  h3{
    font-family: $font-family-monospace;
    font-size: 24px;
  }

  h4{
    color: $black;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 18px;
  }

  p{
    font-size: 16px;
    color:$special-color;
    font-family: $font-family-monospace;
  }
}
}

  /* .content{
      h2 {
        font-family: $font-family-monospace;
        color: $white;
        font-size: 16px;
        margin-top: 36px;
      }

      .row{
        display: flex;
        justify-content: space-between;
      }

      h3 {
        font-family: $roboto-font-family;
        color: $primary-color;
        font-size: 32px;
        margin-top: 36px;
        font-style: italic;
      }

      .description {
        color: $white;
        font-size: 20px;
        text-align: center;
        margin-top:30px;
      }

      .hp_subbtn {
        color: $white;
        font-size: 15px;
        text-align: left;
        margin-top:30px;
      }

      .inputs {
        margin-top: 40px;
        margin-right: 117px;
        background: $white;
        height: 72px;
        border-radius: 15px;
        display: flex;
        align-items: flex-end;
        position: relative;
        font-family: $font-family-monospace !important;
       

        .divider {
          position: absolute;
          height: 40px;
          width: 1px;
          border-left: 1px solid rgba(84, 80, 85, 1);
          left: 50%;
          top: 16px;
          font-family: $font-family-monospace !important;
        }
        
        .input {
          width: 50%;
          padding: 16px;
          float: left;
          font-family: $font-family-monospace !important;
        }

        
      }

      .badges{
        margin-top:45px
      }

      .btn {
        margin-top: 40px;
        height: 80px;
        width: 308px;
        font-weight: 500;
        font-size: 20px;
        color: $primary-color !important;
      }
      .btn-outline-default{
        border-color: $primary-color !important;
      }
    }
  }

  .Introduction {
    padding: 90px 0 215px 0;

    .Cards {
      margin: 0 13% 0 42%;

      .Card {
        min-height: 220px;
        background-image: radial-gradient(circle at 90% 115%, #f3f2f3, #ffffff);
        border-radius: 10px;
        border-bottom: solid 1px #e7e6e7;;
        padding: 25px 32px;
        display: flex;
        align-items: center;

        .content {
          h3 {
            padding-top: 30px;
            text-transform: uppercase;
            font-size: 30px;
            font-style: italic;
            font-weight: 600;
            color: #0b060d;
          }

          span {
            display: block;
            font-family: $font-family-monospace;
          }
        }

        .picto{
          margin-left:30px;
        }
      }
    }
  }

  .Comments {
    background-image: linear-gradient(rgb(255, 255, 255), rgb(243, 242, 243));
    // padding: 0 13%;
    display: flex;
    flex-direction: row;
  
    .CommentContainer {
      position: relative;
      top: -72px;
      width: 33%;
      .Comment {
        // width: 19%;
        // max-width: 368px;
        border-radius: 10px;
        background: $white;
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.08), 0 6px 10px 0 rgba(0, 0, 0, 0.06);
        margin: 0 16px;
        padding: 32px;
    
        .type {
          background: $secondary-color;
          color: $primary-color;
          border-radius: 12px;
          height: 24px;
          padding: 0 12px;
          display: inline;
          text-transform: uppercase;
          font-style:italic;
          font-family: $roboto-font-family;
        }
    
        .title {
          margin-top: 20px;
          font-family: $roboto-font-family;
          font-size: 30px;
          font-weight: 600;
          font-style: italic;
          text-transform: uppercase;
        }
    
        .introduction {
          font-family: $font-family-monospace;
          font-size: 16px;
        }
    
        .content {
          font-family: $font-family-monospace;
          font-weight: 400;
          background: $secondary-color;
          border-radius: 5px;
          color: $white;
          padding: 24px;
          margin-top: 40px;
    
          img { 
            margin-bottom: 18px;
            display: block; 
          }
    
          .author {
            font-weight: 700;
            margin-top: 14px;
          }
        }
      }
    }
  }

  .About {
    background: rgb(243, 242, 243);
    display: flex;
    padding: 20px 0;
    .left {
      width: 62%;
      margin: 48px 0;
      background-color: rgba(16, 21, 38, 1);
      color: $white;
      padding: 88px 62px 90px 19%;

      h3 {
        font-family: $roboto-font-family;
        font-size: 40px;
        font-weight: 600;
        font-style: italic;
        padding-right: 66px;
        margin-bottom: 38px;
        text-transform: uppercase;
      }

      .slogan {
        font-family: $font-family-monospace;
      }

      .Cards {
        display: flex;
        flex-wrap: wrap;
        margin-top: 56px;
        font-family: $font-family-monospace;

        .Card {
          width: 25%; 
          padding: 0 16px;
          margin-bottom: 58px;
          // background: #ccc;
        }
      }

      .buttons {
        .btn {
          margin: 0 0 16px 0;

          &.btn-outline-default {
            margin-left: 16px;
          }
        }
      }

      span {
        font-family: $font-family-monospace;
      }
    }

    .right {
      width: 38%;
      background: url('../../img/HomePage/About.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      // min-height: 600px;
      border-radius: 25px 0 0 25px;
      box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.08), 0 6px 10px 0 rgba(0, 0, 0, 0.06);
    }
  }

  .Where {
    background-image: linear-gradient( rgb(243, 242, 243), rgb(255, 255, 255));
    text-align: center;
    padding-top: 185px;
    padding-bottom: 144px;
    box-shadow: inset 0 -4px 4px rgb(243, 242, 243);

    h3 {
      font-family: $roboto-font-family;
      font-size: 40px;
      font-weight: 600;
      font-style: italic;
      text-transform: uppercase;
    }

    .Cities {
      padding-top: 103px;
      margin: 0 19%;
      display: flex;
      flex-wrap: wrap;
      font-family: $font-family-monospace;

      .City {
        width: 20%;
        margin-bottom: 16px;


        .Container {
          margin: 0 16px;
          height: 56px;
          background: rgba(243, 242, 243, 1);
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
        }


        &.large {
          width: 40%;
        }

        &.outline {
          .Container {
            border: 1px solid rgba(243, 242, 243, 1);
            background: transparent;
            color: rgba(135, 132, 136, 1);
            font-weight: 500;
          }
        }
      }
    }

    .Start {
      margin: 0 19%;

      .Container {
        margin: 136px 16px 0;
        background-color: rgba(16, 21, 38, 1);
        padding: 48px;
        border-radius: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .Text {
          font-family: $roboto-font-family;
          font-size: 40px;
          font-weight: 600;
          font-style: italic;
          text-transform: uppercase;
          color: $white;
          text-align: left;
        }
        .btn {
          width: 208px;
        }
      }
    }
  }
} */

// Medium
@media screen and (min-width: 768px) and (max-width: 992px) { 
  .HomePage {
    .Introduction {
      .Cards {
        margin-left: 24%;
      }
    }
  }
}

// Mobile
@media screen and (max-width: 768px) { 

  .firstContent{
    padding-top: 50px !important;
    //margin-left: 19%;
    //width: 688px;
    margin: 0 !important;
    margin-left: 5% !important;
    

    #first_column{
      padding-right: 0px !important;
    }

    #cta{
      flex-direction: column!important;
    }
  }

  .cardRow{
    padding: 20px !important;
  }


  .textRow{
    padding: 20px !important;
  }


  .HomePage {
    background: $white;
    .Header {
      height: auto;

      .navbar {
        padding: 0;

        .navbar-brand {
          margin: auto;
        }
      }
      
      .content {
        width: 100%;
        max-width: 100%;
        margin: 0;
        padding: 60px 32px 196px 32px;

        .inputs {
          margin-top: 40px;
          margin-right: unset;
          background: transparent;
          height: auto;
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          align-items: unset;
          position: relative;
  
          .divider {
            display: none;
          }
          
          .input {
          
            width: 100%;
            padding: 0 16px;
            float: left;
            background: $white;
            border-radius: 15px;
            height: 48px;
            margin: 8px 0;

            .md-form {
              padding: 0;
              margin: 0;
            }

            label.active {
              display: none;
            }
          }

          
        }

        .badges{
          margin-top:45px;
          text-align: center !important;
        }

        h1{
          text-align: center;
          margin-bottom: 15px;
        }
        
        .row{
          display: flex;
          justify-content: center;
        }

        h3{
          margin-top: 10px;
          font-size: 20px;
          text-align: left;
        }

        .description {
          font-size: 15px;
        }

        .btn {
          margin-top: 40px;
          height: auto;
          width: auto;
          font-weight: 500;
          font-size: 16px;
          //left: 50%;
          //margin-left: -104px;
        }
      }
    }

    .Introduction {
      padding: 0 0 80px 0;
      background: url('../../img/HomePage/Mobile/Introduction.png') $white;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom center;

      .Cards {
        margin: 0 16px;
  
        .Card {
          min-height: unset;
          background: transparent;
          border: none;
          margin-bottom: 64px;
          display: flex;
          flex-direction: column;
          
          .content {
            order: 2;
            text-align: center;
  
            span {
              margin: 0;
            }
          }

          .picto {
            order: 1;
            margin-left:0;
          }
        }
      }
    }

    .Comments {
      flex-direction: column;
    
      .CommentContainer {
        width: 100%;
        .Comment {
          width:auto;
          margin: 0 16px 16px 16px;
        }
      }
    }

    .About {
      background-color: rgba(16, 21, 38, 1);
      flex-direction: column;
      padding: 0;
      .right {
        order: 1;
        margin: -32px 16px 0 16px;
        width: auto;
        height: 408px;
        border-radius: 25px;
      }

      .left {
        order: 2;
        width: auto;
        margin: 48px 16px 24px 16px;
        padding: 0;
        text-align: center;

        h3 {
          margin: 0 0 38px 0;
          padding: 0;
        }

        .Cards {
          .Card {
            width: 50%;
          }
        }

        .buttons {
          display: flex;
          flex-direction: column;
          padding: 0 16%;

          .btn {
            margin: 0 0 16px 0;
            display: block;
  
            &.btn-outline-default {
              margin-left: unset;
            }
          }
        }
      }
    }

    .Where {
      padding: 64px 16px;
      background: $white;
      box-shadow: none;

      .Cities {  
        margin: 0 -16px;
        .City {
          width: 50%;  
  
          &.large {
            margin: 0 6%;
            width: 88%;
          }
        }
      }
      .Start {
        margin: 0;
  
        .Container {
          display: flex;
          justify-content: unset;
          flex-direction: column;
  
          .Text {
            font-family: $roboto-font-family;
            font-size: 40px;
            font-weight: 600;
            font-style: italic;
            text-transform: uppercase;
            color: $white;
            text-align: center;
          }
          .btn {
            margin-top: 64px;
            width: 208px;
          }
        }
      }
    }
  }
}