.PassengerProfile {
  width: 100%;
  margin-right: 0;
  margin-left: 0;

  .Informations {
    display: flex;
    flex-direction: column;
  }

  .Medical {
    display: flex;
    flex-direction: column;
  }
}