// Sidebar Homepage 
.navbar.sidebar::-webkit-scrollbar {
  display: none;
}
.navbar.sidebar {
  z-index: 1399;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 224px;
  padding: 0;
  align-content:space-between;
  background-image: url('../../img/side-menu-manager.png');
  background-repeat: no-repeat;
  background-size: cover;
 

  .sidebar-sticky {
    width: 100%;

    .navbar-brand {
      padding-right: 33px !important;
      padding-left: 33px !important;
      color: $primary-color;
      text-align: center;
      width: 100%;
    }
  
    .nav-link {
      font-family: $roboto-font-family;
      color: white;
      height: 35px;
      margin: 5px 0 5px 20px;
      display: flex;
      align-items: center;
      font-size: 19px;
      font-weight: 600;
      text-transform: uppercase;
      font-style: italic;
  
      &:hover,
      &.active {
        color: $primary-color;
      }
  
      &.active {
        border-left: 4px solid $primary-color;
      }
    }
  }

  .horizontalDividerWhite {
    height: 1px;
    background: $white;
  }

  .SidebarButton{
      margin: 8px 0px 0px 0px;
      padding: 8px;
      border-radius: 10px;
      //box-shadow: 5px 5px 10px #d1d0d1, -5px -5px 10px #ffffff !important;
      background-color: $secondary-color;
      font-family: $font-family-monospace;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size:0.75em;
      text-transform: none;
      font-style: normal;

      .fas{
        font-size: 1.2em !important;
      }
  
  }

  .btn_sidebar{
    justify-content: center;
    align-items: center;
    background-color: $primary-color !important;
    font-size: 20px;
    font-weight: 700;
    font-style: normal !important;
    font-family: 'Montserrat';
    color:$secondary-color !important;
    border-radius: 10px;
    // margin: 0px 80px;
    margin: 0 15px;
    // padding:0 0 0 15px !important;
    padding: 0 !important;

    .nav-link {
      color: white;
      cursor: pointer;

     
        // height: 72px;
        font-size: 16px;
        font-weight: 400;
        text-transform: none;
        font-style: normal;
        padding: 0;
        text-align: center;
        width: 100%;

      }
  }

  .bottom {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;
    font-family: $font-family-monospace;

    .nav-link {
      color: white;
      cursor: pointer;
      height:auto;

      &.primary {
        // height: 72px;
        font-size: 16px;
        font-weight: 400;
        text-transform: none;
        font-style: normal;
        padding: 0;
        text-align: center;
        width: 100%;
        //color: $primary-color;

        .fa {
          margin-right: 8px;
        }
      }
    }
  }
}
