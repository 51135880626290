.PhoneInput {
  padding-top : 12px;
  padding-bottom : 9px;
}

// .PhoneInput__country--native {
//   align-self: flex-end;
//   margin-bottom: 6px;
// }

// .PhoneInput__icon {
//   width: 1.5em;
//   height: auto;
//   border: none;
// }

// .PhoneInput__icon-image {
//   border: 1px solid $input-border-color;
// }
