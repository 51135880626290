.CreateRide {
  width: 100%;
  margin-right: 0;
  margin-left: 0;

  .Path {
    font-family: $font-family-monospace;
    margin-top: 40px !important;

    .Inputs {
      display: flex;
      align-items: center;

      .Picto {
        padding: 0 12px 0 0;
      }

      .Input {
        flex-grow: 1;
      }
    }
  }

  .Passenger {
    display: flex;
    flex-direction: column;

    .Inputs {
      display: flex;
      align-items: center;

      .Picto {
        padding: 0 12px 0 0;
      }

      .Input {
        flex-grow: 1;
      }
    }

    .List {
      height: 368px;
      overflow: auto;
    }

    .modal-content {
      overflow: hidden;
    }
  }

  .OtherInformation {
    display: flex;
    flex-direction: column;

    .Inputs {
      display: flex;
      align-items: baseline;

      .Picto {
        padding: 0 12px 0 0;
      }

      .Input {
        flex-grow: 1;
      }
    }
  }

  .MuiInputBase-input {
  //  width: 40px !important;
    padding-bottom: 0.5rem;
  }
}

