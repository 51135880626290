label,
p {
  font-size: 0.9rem;
}

// Formulaires
label {
  font-family: $font-family-monospace;
}

.md-form {
  margin-bottom: 0;
}

.FormRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 2rem;

  .Picto {
    padding-top: 21px;
    padding-right: 16px;
  }

  .Input {
    flex-grow: 1;
  }
}
