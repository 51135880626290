.OperatorVehicles {
  width: 100%;
  margin: 0!important;
}

.OperatorCard {
  overflow: hidden;
  margin-top: 25px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 10px;
  height: 75px;
  background-color: rgb(16, 21, 38);
  font-family: $font-family-monospace;
  
  .Brand {
    padding-left: 10px;
    font-size: 1.5em;
    color: rgb(252, 238, 38);
  }

  .Registration {
    padding-left: 10px;
    font-size: 1.2em;
    color: rgb(255, 255, 255);
  }

  .Check {
    color: rgb(252, 238, 38);
    text-align: center;
    margin-top: 13px;
  }
}