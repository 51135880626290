@import 'nebulea/table';
@import 'nebulea/input';
@import 'nebulea/form';
@import 'nebulea/btn';
@import 'nebulea/audioPlayer';
@import 'nebulea/scrollbar';
@import 'nebulea/footer';
@import 'nebulea/navbar';
@import 'nebulea/sidebar';
@import 'nebulea/mapbox';
@import 'nebulea/cardTitle';
@import 'nebulea/datepicker';
@import 'nebulea/phoneNumberInput';
@import 'nebulea/operator';
@import 'nebulea/operatorVehicles';
@import 'nebulea/register-signin';
@import 'nebulea/driver';
@import 'nebulea/business';
@import 'nebulea/homePage';
@import 'nebulea/listDG';
@import 'nebulea/managerSchedule';
@import 'nebulea/managerParams';
@import 'nebulea/managerDashboard';
@import 'nebulea/managerQuotation';
@import 'nebulea/medical';
@import 'nebulea/detailsDG';
@import 'nebulea/passenger';
@import 'nebulea/passengerProfile';
@import 'nebulea/passengerFavorites';
@import 'nebulea/passengerRideDetails';
@import 'nebulea/passengerChangeDG';
@import 'nebulea/passengerEditRide';
@import 'nebulea/passengerFormPayment';
@import 'nebulea/rideDetails';
@import 'nebulea/driverApp';
@import 'nebulea/createRide';
@import 'nebulea/connectUser';
@import 'nebulea/callCard';
@import 'nebulea/onboarding';
@import 'nebulea/chat';
@import 'nebulea/customBooking';

@import 'custom-styles-mobile';

body {
  background: $extra-light-gray;
}

/* @media (prefers-color-scheme: dark) {
  body {
    background: $black;
  }
} */

.pSmall {
  font-size: 0.7rem !important;
}

.fontSize11rem {
  font-size: 1.1rem;
}

ul {
  padding: 0;
}
li {
  list-style-type: none;
}

.reset-uppercase {
  text-transform: initial;
}

.link {
  cursor: pointer;
}

.container {
  &.main {
    margin-top: 80px;
    width: 100%;
    max-width: 100%;
    padding: 0;

    .row {
      margin: 0;
    }
  }

  &.manager-desktop {
    width: 100%;
    max-width: 100%;
    padding-left: 224px;
    margin: 0;

    .row {
      margin: 0;
      padding-top: 0px;
    }
  }

  &.manager-mobile {
    width: 100%;
    max-width: 100%;
    padding: 0px;
    margin: 0;
  }

  &.passenger-desktop {
    width: 100%;
    max-width: 100%;
    padding-left: 224px;
    margin: 0;

    .row {
      margin: 0;
      padding-top: 0px;
    }
  }

  &.passenger-mobile {
    width: 100%;
    max-width: 100%;
    padding: 0px;
    margin: 0;
  }
}

.rounded-right {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.font-roboto {
  font-family: Roboto, sans-serif;
}

.maxWidth-80 {
  max-width: 80px;
}

.font-monospace {
  font-family: $font-family-monospace;
}

.FixedHeader{
  z-index: 10000;
  position: fixed !important;
  width: calc(100vw - 254px);
}

.marginTop130{
  margin-top: 130px !important;
}

// Simple class to override if needed --------------
.zIndex2 {
  z-index: 2;
}

.zIndex5 {
  z-index: 5;
}

.zIndex1079 {
  z-index: 1079;
}

.zIndex1080 {
  z-index: 1080;
}

.width100 {
  width: 100%;
}

.width90 {
  width: 90%;
}

.width80 {
  width: 80%;
}

.maxWidth10 {
  max-width: 10%;
}

.text-black {
  color: black !important;
}

.height100vh {
  height: 100vh;
}

.smooth-transition {
  transition: 3s ease;
}

.IconCheck {
  border: 2px solid #878488;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

// Simple class to override if needed --------------

// Dividers ---------------------------------------
.verticalDivider {
  display: block;
  width: 15%;
  height: 1px;
  background-color: $light-gray !important;
  transform: rotate(90deg);
  margin: auto;
}

.horizontalDivider {
  display: block;
  width: 100%;
  height: 1px;
  background-color: $extra-light-gray !important;
  margin: auto;

  &-secondary {
    background-color: $secondary-color !important;
  }

  &-lightgrey {
    display: block;
    width: 100%;
    height: 1px;
    margin: auto;
    background-color: $grey-divider !important;
  }
}

.d-none {
  display: none !important;
}

.roundSelectBox {
  border-radius: 24px !important;
  padding: 8px 24px;
}

.status {
  &-processing {
    color: #ff820d;
  }
  &-assigned {
    color: #549111;
  }
  &-canceled {
    color: #e7808d;
  }
  &-completed {
    color: #a9c888;
  }
  &-quote {
    color: #878a92;
  }
  &-rejected {
    color: #d0021b;
  }
  &-to_be_assigned {
    color: #fceb26;
  }
}

.ride-status {
  padding: 6px 15px;
  border-radius: 20px;
  font-weight: bold;
  text-align: center;
}

.ride-status {
  &-processing {
    background-color: #ff820d;
  }
  &-assigned {
    background-color: #549111;
    color: white;
  }
  &-canceled {
    background-color: #e7808d;
  }
  &-completed {
    background-color: #a9c888;
  }
  &-quote {
    background-color: #878a92;
    color: white;
  }
  &-rejected {
    background-color: #d0021b;
    color: white;
  }
  &-to_be_assigned {
    background-color: #fceb26;
  }
}

.loader {
  font-weight: 600;
  font-style: italic;
  text-transform: uppercase;
}

.filters {
  padding: 0 !important;
}

// Permet de désactiver le cadre autours d'un élément au clic
:focus {
  outline: unset !important;
}

//Hide Arrows From Input Number --------------
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

// Tabs
.nav-tabs .nav-link.active {
  color: #fceb27;
  background-color: #101526;
  border: 1px solid #101526;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.nav-link {
  padding: 1px 10px;
  font-family: $font-family-monospace, 'Titillium Web', sans-serif;
}

.txt-btn {
  font-size: 12px;
  font-family: $font-family-monospace, 'Titillium Web', sans-serif;
}

.md-form {
  margin-top: 0 !important;
}
