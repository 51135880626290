.scrollbar {
  float: left;
  height: auto;
  // background: #fff;
  overflow-y: auto;
  scrollbar-color: $primary-color $secondary-color;
  scrollbar-width: thin;
}

.force-scroll {
  overflow-y: auto;
  // height: 95vh;
}

.force-scroll-pop-up {
  overflow-y: auto;
  height: 95vh;
}

.scrollbar-indigo::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: $secondary-color;
  border-radius: 10px;
}

.scrollbar-indigo::-webkit-scrollbar {
  width: 12px;
  background-color: $primary-color;
}

.scrollbar-indigo::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: $primary-color;
}

.bordered-indigo::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border: 1px solid $secondary-color;
}

.bordered-indigo::-webkit-scrollbar-thumb {
  -webkit-box-shadow: none;
}

.square::-webkit-scrollbar-track {
  border-radius: 0 !important;
}

.square::-webkit-scrollbar-thumb {
  border-radius: 0 !important;
}

.thin::-webkit-scrollbar {
  width: 6px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.hide-scrollbar {
  -ms-overflow-style: none;
}
