.Passenger--Ride {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: $font-family-monospace;
  font-weight: 400;
  font-size: 12px;
  
  .AddressCard {
    padding-left:20px;
    .address {
      color: $light-gray;
      font-size: 12px;
      &::first-line {
        font-weight: 700;
        color: $black;
        font-size: 14px;
      }
    }
  }

  .TravelCardPassenger{
    margin-top:10px !important;
    margin-bottom:25px !important;
    max-width: 100vw !important;
    border-radius: 10px !important;
    background: #F3F2F3 !important;
    box-shadow:  5px 5px 10px #d1d0d1, 
                 -5px -5px 10px #ffffff!important;

    .Badge{
      background-color: #888888;
      padding: 5px 10px;
    }
  }

  .DateTimeTravelCard{
    font-family: $roboto-font-family;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    font-style: italic;
  }

  .TextTravelCard{
    font-family: $font-family-monospace;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    margin-top: 0;
  }

  .TitleTravelCard{
    font-family: $roboto-font-family;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    font-style: italic;
  }

  .DriverBadge{
    background-color: #e7e6e7;
    padding: 4px 16px;
    border-radius:20px;
  }
  
  .FirstLineAddress{
    font-weight: 700;
    font-size: 14px;
  }

  .toggle {
    margin: 0 16px;
    width: calc(100% - 32px);
    position: absolute;
    top: 66px;
    font-family: $roboto-font-family;
    font-weight: 600;
    font-size:18px !important;
    font-style: italic;
    text-transform:uppercase; 

    .waitingRidesQuantity {
      color: $white !important;
      font-family: $font-family-monospace;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      padding: 0px 8px;
      font-size:16px;
    }

    .toggleOn {
      .waitingRidesQuantity {
        background: $white !important;
        color: $black !important;
      }
    }
    @media screen and (max-width: 768px) {
      p {
        font-size: 18px;
      }
    }  
  }

  .NextRides {
    width: 100%;
    padding: 126px 16px 70px 16px;

    .row {
      margin: 0;

    }
  }

  .PassedRides {
    width: 100%;
    padding: 126px 16px 70px 16px;

    .row {
      margin: 0;

    }
  }

  .border-secondary {
    border: 2px solid $light-gray !important;
    border-radius: 5px !important;
  }
  
}