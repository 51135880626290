.react-calendar-timeline {
  .rct-header-root {
    background: $secondary-color !important;

    .rct-calendar-header {
      border-top: none;
    }
  }

  .rct-outer {
    .rct-sidebar {
      .rct-sidebar-row {
        background: transparent;
        border-style: none none dashed;
        border-bottom-width: 3px;
        border-bottom-color: #dee2e6;
        font-size: 14px;
      }

      .rct-sidebar-row:nth-child(2) {
        border-style: none none solid;
        border-bottom-width: 4px;
        border-bottom-color: $light-gray;
      }

      .rct-sidebar-row:nth-child(3) {
        border: none;
      }

      .rct-sidebar-row:last-child {
        border: none;
      }

      .rct-sidebar-row-odd {
        background: rgb(238, 238, 238);
      }
    }
  }

  .rct-dateHeader {
    border: none;
    border-left: 3px #dee2e6 dashed;

    span {
      text-align: left;
    }
  }

  .rct-dateHeader-primary {
    color: #212529;

  }

  .rct-vertical-lines {
    .rct-vl {
      border: none;
    }

    .rct-vl.rct-day-6,
    .rct-vl.rct-day-0 {
      background: inherit !important;
    }
  }

  .rct-horizontal-lines {
    .rct-hl-even {
      background: transparent;
      border-style: none none dashed;
      border-bottom-width: 3px;
      border-bottom-color: #dee2e6;
    }

    .rct-hl-odd {
      background: rgb(238, 238, 238);
      border-style: none none dashed;
      border-bottom-width: 3px;
      border-bottom-color: #dee2e6;
    }

    .rct-hl-odd:nth-child(2) {
      border-style: none none solid;
      border-bottom-width: 4px;
      border-bottom-color: $light-gray;
    }

    .rct-hl-even:nth-child(3) {
      border: none;
    }

    .rct-hl-odd:last-child {
      border: none;
    }

    .rct-hl-even:last-child {
      border: none;
    }
  }
}

.Schedule {
  margin-bottom: 30px !important;

  .Header {
    width: 100%;
    height: 100%;

    font-family: $font-family-monospace;
    font-weight: 400;
    font-size: 12px;

    .Box {
      background: transparent;
    }

    .row {
      display: inherit;
    }

    .AddressCard {
      padding-left: 20px;

      .address {
        color: $light-gray;
        font-size: 12px;

        &::first-line {
          font-weight: 700;
          color: $black;
          font-size: 14px;
        }
      }
    }

    .TravelCardPassenger {
      margin-top: 10px !important;
      margin-bottom: 25px !important;
      max-width: 100vw !important;
      border-radius: 10px !important;
      background: #f3f2f3 !important;
      box-shadow: 5px 5px 10px #d1d0d1, -5px -5px 10px #ffffff !important;

      .Badge {
        background-color: #888888;
        padding: 5px 10px;
      }
    }

    .DateTimeTravelCard {
      font-family: $roboto-font-family;
      font-weight: 600;
      font-size: 18px;
      text-transform: uppercase;
      font-style: italic;
    }

    .TextTravelCard {
      font-family: $font-family-monospace;
      font-weight: 400;
      font-size: 16px;
      font-style: normal;
      margin-top: 0;
    }

    .TitleTravelCard {
      font-family: $roboto-font-family;
      font-weight: 600;
      font-size: 18px;
      text-transform: uppercase;
      font-style: italic;
    }

    .DriverBadge {
      background-color: #e7e6e7;
      padding: 4px 16px;
      border-radius: 20px;
    }

    .FirstLineAddress {
      font-weight: 700;
      font-size: 14px;
    }

    .Ride {
      position: fixed;
      background: #f3f2f3 !important;
      left: 139px;
      bottom: 44px;
      width: 300px !important;
      height: 141px !important;
      z-index: 85;
    }
  }
}