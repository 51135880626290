.Passenger--Ride,
.Operator--Rides
{
  .Edit {
    width: 100%;
  
    .Header {
      background: $secondary-color;
      padding: 76px 16px 22px 16px;
      width: 100%;
      color: $white;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  
      h3 {
        color: $primary-color;
        text-transform: uppercase;
        font-style: italic;
        font-weight: 600;
        font-size: 18px;
      }

      .Pictos {
        svg {
          margin: 0 4px;
        }
      }
    } 

    .Address {
      background: $secondary-color;
      display: flex;
      flex-direction: row;
      padding: 16px;
      padding-bottom: 48px;

      .Pictos {
        background: $white;
        width: 32px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4px 0;
      }

      .Inputs {
        width: calc(100%);
        padding-left: 16px;

        input,
        label {
          color: $white;
        }

        input {
          border-bottom-width: 2px;
        }
      }

      .Line {
        height: 2px;
        background: $white;
      }
    }

    .Cards {
      .Card {
        margin: 24px 16px 0 16px;
        background: $white;
        border-radius: 5px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        padding: 24px 16px;

        .FormRowVertical {
          display: flex;
          flex-direction: column;

          .Label {
            padding-left: 40px;
          }
        }

        .FormRow {
          display: flex;
          flex-direction: row;
          align-items: center;

          label {
            &.active {
              color: $black;
              transform: translateY(-16px); 
            }
          }

          .Picto {
            padding-top: 21px;
            padding-right: 16px;
          }

          .SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2 {
            left: -44px !important;
          }

          .md-form {
            width: 100%;
            label,
            input {
              color: $black;
            }
          }
        }

        &.Services {
          .List {
            display: flex;
            flex-wrap: wrap;
  
            .Service {
              flex: 0 50%;
              width: 100%;

              padding: 4px 0;

              &:nth-child(odd) {
                padding-right: 4px;
              }
              &:nth-child(even) {
                padding-left: 4px;
              }

              .Container {
                position: relative;
                border: 2px solid $light-gray;
                border-radius: 5px;
                text-align: center;
                color: $black;
                font-size: 16px;
                font-family: $font-family-monospace;
                height: 88px;
                justify-content: center;
                align-items: center;
                display: flex;

                .Selected {
                  display: none;
                  top: 8px;
                  right: 8px;
                  position: absolute;
                }

                &.Active {
                  background: $secondary-color;
                  border-color: $secondary-color;
                  color: $primary-color;
                  font-weight: 600;

                  .Selected { 
                    display: block;
                  }
                }

              }
            }
          }
        }

        &.Comment {
          margin-bottom: 16px;
          .md-form {
            padding: 0;
            margin: 0;
            label {
              display: none;
            }
          }
        }
      }
    }
  }
}