.cardTitlePadding {
    padding: 5px 13px;
    display: flex;
    flex-direction: row;
    font-family: $roboto-font-family;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.14;
    color: $black-base;
    background: $white-base;
    border-radius:12px;
    margin-bottom: 16px !important;
    

    div {
        margin-left: 12px;
    }
}

.informationLeftBadgePadding {
    padding-left: 10px;
    padding-top: 4px;
    padding-right: 15px;
    padding-bottom: 4px;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.14;
    font-family: $font-family-monospace;
}

.informationRightBadgePadding {
    padding-left: 8px;
    padding-top: 4px;
    padding-right: 8px;
    padding-bottom: 4px;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.14;
    font-family: $font-family-monospace;

}