.Onboarding {

  .Cards{
    margin-top: 10px;
    height: 100%;
    padding: 50px 30px;
    justify-content: center;
  }

  .Card{
    width: 70%;
    min-height: 400px;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    background-color: $extra-light-gray;
   /*  box-shadow:  18px 18px 36px #12141a, 
             -18px -18px 36px #484e66; */
    color:$black;
    text-align: left;

    h1{
      font-weight: 600;
      width: 100%;
      text-align: left;
    }

    p{
      font-size: 1.1rem;
    }

    p.text-danger {
      font-size: 0.9rem;
    }

    .row60{
      width: 60%;
    }

    .footer{
      font-size: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p{
        max-width: 70%;
      }
    }
  }

}

// Mobile
@media screen and (max-width: 768px) { 
  .Onboarding {
    padding-top:2rem; 

    [class*=col-]{
      margin: 0px;
      padding: 1rem;
    }

    .Cards{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0px !important;
      height: 100%;
      padding: 10px 0px;
    }
  
    .Card{
      width: 100%;
      min-height: 400px;
      padding: 20px 15px;
      margin: 0px !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 16px;
      background-color: $extra-light-gray;
      box-shadow:  18px 18px 36px #12141a, 
               -18px -18px 36px #484e66;
      color:$black;
      text-align: left;
  
      h1{
        text-align: center;
      }
  
      p{
        font-size: 1.1rem;
      }

      .row60{
        width: 100%;
      }

      .md-form{
        margin-top:0px;
      }
  
      .footer{
        font-size: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        p{
          max-width: 100%;
        }
      }
    }
  
  }
}