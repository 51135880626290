// Navbar Homepage
.navbar {
  box-shadow: none;
  padding: 0 16px;

  .nav-item {
    display: flex;
    align-items: center;
  }
  &.default-color {
    background-color: $secondary-color !important;
  }
  a {
    color: $primary-color;
  }

  .navbar-brand {
    a {
      font-weight: 800;
    }
  }

  .navbar-collapse {
    &.show {
      padding-bottom: 16px;
    }
  }
}

.navbar.secondary {
  background-color: $extra-light-gray !important;

  button {
    .navbar-toggler-icon {
      background: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(11, 6, 13, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  }

  .navbar-nav {
    padding: 0 16px;
    .nav-item {
      .nav-link {
        color: $black;

        &:hover {
          color: rgba(11, 6, 13, 1);
        }

        .btn {
          &.btn-outline-primary {
            border-color: $black !important;
            color: $black !important;
          }
        }
      }
    }
  }
}

.navbar-left {
  left: 0;
  position: absolute;
  top: 0;
}

.navbar-right {
  right: 0;
  position: absolute;
  top: 0;
}

// Mobile
@media screen and (max-width: 768px) {
  .navbar {
    padding: 0 16px !important;

    .navbar-brand {
      margin: auto;
    }

    button.navbar-toggler {
      // position: fixed;
      // top: 9px;
      padding: 0px;
      padding-top: 6px;
      border: none;
      font-size: 2em;
    }
  }
}
