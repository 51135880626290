.Manager {
  color:$black;

  .Informations {
    display: flex;
    flex-direction: column;

    .Logo {
      text-align: center;

      label {
        cursor: pointer;
        font-family: $font-family-monospace;
      }

      input {
        display: none;
      }

      p {
        margin: 0px;
        text-align: center;
        font-size: smaller;
        color: grey;
        font-family: $font-family-monospace;
      }
    }

    .Inputs {
      display: flex;
      align-items: center;

      .Picto {
        padding: 0 12px 0 0;
      }

      .Input {
        flex-grow: 1;
      }
    }
  }

  .Photos {
    display: flex;
    flex-direction: row;
    background: $extra-light-gray;
    background-color: white;
    padding: 0;

    .Label {
      text-align: center;

      label {
        cursor: pointer;
        font-family: $font-family-monospace;
      }

      input {
        display: none;
      }
    }

    .Photo {
      width: 33%;
      max-height:100%;
      align-self: center;

      &:nth-child(2) {
        width: 34%;
        padding: 0 6px 0 6px;
      }

      &:first-child {
        img {
          border-radius: 5px 0 0 5px;
        }
      }
      &:last-child {
        img {
          border-radius: 0 5px 5px 0;
        }
      }

      img {
        min-width: 100%;
        max-height: 100%;
        overflow:hidden;
        display: inline-block;
      }
    }
  }

  .Service {
    display: flex;
    flex-direction: column;
    font-family: $font-family-monospace;
    font-style: normal !important;
    
    h4 {
      font-weight: 700 !important;
    }
  }

  .SocialNetwork {
    display: flex;
    flex-direction: column;

    .Inputs {
      display: flex;
      align-items: center;

      .Picto {
        padding: 0 12px 0 0;
      }

      .Input {
        flex-grow: 1;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Manager {
    display: flex;
    flex-direction: column;

    .Photos {
      order: 4;
      display: flex;
      flex-direction: column;
      background: $extra-light-gray;
      padding: 0;
  
      .Photo {
        width: 70%;
        max-height: 100%;
  
        &:nth-child(2) {
          width: 70%;
          padding: 6px 0 6px 0;
        }
  
        &:first-child {
          img {
            border-radius: 5px 5px 0 0;
          }
        }
        &:last-child {
          img {
            border-radius: 0 0 5px 5px;
          }
        }
  
        img {
          min-width: 100%;
          max-height: 100%;
          overflow:hidden;
          object-fit: cover;
        }
      }
    }
  }
}