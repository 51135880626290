.DetailsRide {
  width: 100%;
  margin-top: 8px !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  min-height: 50vh;

  @media screen and (max-width: 768px) {
    margin-top: 60px !important;
  }

  .TwoCols {
    display: flex;
    flex-direction: row;
    font-family: $font-family-monospace;
    font-size: 16px;
    width: 100%;
    justify-content: space-between;
  }

  .Header {
    background: $secondary-color;
    margin: 0px 15px !important;
    border-radius: 10px !important;
    padding: 18px;
    display: flex;
    flex-direction: column;
    color: $white;

    @media screen and (max-width: 768px) {
      margin: 0px !important;
      border-radius: 0px !important;
      padding-right: 12px;
    }

    .TwoCols {
      .Paid {
        display: flex;
        align-items: center;
        
        .Picto {
          width: 24px;
          height: 24px;
          border: 1px solid $white;
          border-radius: 4px;
          float: left;
          margin-right: 8px;
        }
        .Invited {
          border-radius: 12px;
          background-color: $primary-color;
          color: $secondary-color;
          padding: 3px 10px;
        }
      }

      .Cancel {
        .ButtonWhite {
          border: 1px solid $white;
          border-radius: 24px;
          height: 32px;
          padding: 0 16px;
          font-size: 12px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 5px 0;
          cursor: pointer;

          .Icon {
            margin-left: 12px;            
          }

        }

        .ButtonYellow {
          border-radius: 24px;
          background-color: $primary-color;
          color: $black;
          height: 32px;
          padding: 0 16px;
          text-align: center;
          font-size: 12px;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }

      &.BookPrice {
        // margin: 32px 0 0 0;

        .Booking {
          color: $primary-color;
          font-family: $roboto-font-family;
          font-style: italic;
          text-transform: uppercase;
          font-size: 18px;
          font-weight: 600;
          padding: 0px;
        }

        .Price {
          font-size: 16px;
          max-width: 250px;
          text-align: right;
          display: flex;
          align-items: flex-end;

          span {
            input {
              padding-right: 12px;
              text-align: right;
              border-bottom: 2px solid $white;
            }
          }

          /* span::after {
            position: relative;
            right: -12px;
            bottom: 31px;
            content: '€';
            color: $white;
          } */
        }
      }
    }

    .Date {
      font-size: 14px;
      margin-top: 3px;
      font-weight: 400 !important;
      font-style: normal !important;
    }

    .Buttons {
      margin: 18px 0 -42px 0;
      display: flex;
      flex-direction: row;
      width: 100%;
      z-index: 4;

      button {
        width: calc(100% + 6px);
        margin: 0;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 3px -2px rgba(0, 0, 0, 0.08), 0 3px 4px 0 rgba(0, 0, 0, 0.06);
        border: unset;
        height: 48px;
        padding: 0;
        text-align: center;
        align-items: center;

        &.Half {
          width: 33%;
          &:first-child {
            margin-right: 4px;
          }
          &:last-child {
            margin-left: 4px;
          }
        }

        .Picto {
          position: absolute;
          left: 16px;
        }
      }
    }
  }

  .Medical {
    border-radius: 10px;
    padding: 18px;
    display: flex;
    flex-direction: column;

    .Button {
      margin: 18px 0 -42px 0;
      display: flex;
      flex-direction: row;
      width: 100%;
      z-index: 4;

      button {
        width: calc(100% + 6px);
        margin: 0;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 3px -2px rgba(0, 0, 0, 0.08), 0 3px 4px 0 rgba(0, 0, 0, 0.06);
        border: unset;
        height: 48px;
        padding: 0;
        text-align: center;
        align-items: center;
      }
    }
  }

  .Cards {
    margin-top: 32px;
    padding: 0 16px;

    .Card {
      margin: 16px 0;
      background: $white;
      border-radius: 4px;
      padding: 16px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 3px -2px rgba(0, 0, 0, 0.08), 0 3px 4px 0 rgba(0, 0, 0, 0.06);

      .cardTitlePadding {
        font-size: 14px;
        font-weight: 600;
      }

      &:last-child {
        margin-bottom: 24px;
      }

      &.TravelCard {
        display: flex;
        flex-direction: row;
        padding: 16px 0;

        .Pictos {
          display: flex;
          flex-direction: column;
          // margin: 16px 0;
          width: 24px;
          height: 96px;
          padding: 12px 0;
          align-items: center;
          background: $secondary-color;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }

        .Addresses {
          margin-top: 8px;
          display: flex;
          flex-direction: column;
          padding: 0 16px;
          font-family: $font-family-monospace;
          font-size: 12px;
          color: $light-gray;
          width: calc(100% - (24px + 114px));

          .Address {
            &::first-line {
              font-size: 14px;
              color: $black;
              font-weight: 600;
            }
          }

          .Divider {
            width: 100%;
            height: 1px;
            background: $secondary-color-light;
            margin: 3px 0px;
          }
        }
      }

      .DateTimeDuration {
        width: 114px;
        padding: 0 8px;
        text-align: right;
        font-family: $font-family-monospace;

        .Date {
          font-size: 16px;
        }
        .Time {
          font-family: $roboto-font-family;
          font-size: 18px;
          font-weight: 600;
          font-style: italic;
          text-transform: uppercase;
        }
        .Duration {
          margin-top: 10px;
          font-family: $font-family-monospace;
          font-size: 12px;
        }
      }

      &.Details {
        .modal-content{
          overflow: hidden;
        }
        
        .Container {
          display: flex;
          flex-direction: row;
          background: $btn-gray;
          border-radius: 5px;
          height: 40px;
          // justify-content: space-between;
          align-items: center;
          padding: 0 8px;
          box-shadow: none;
          width: inherit;

          .Input {
            font-family: $font-family-monospace;
            font-size: 14px;
            padding-left: 12px;
          }
        }

        .PulseIcon{
          animation: pulse 2s infinite;
          padding-left: 15px
        }

        @keyframes pulse {
          0% {
            transform: scale(0.95);
          }
        
          70% {
            transform: scale(1.2);
          }
        
          100% {
            transform: scale(0.95);
          }
        }

        .Modal {
          min-height: 5vh;
        }

        .Body {
          min-width: 100%;
          max-width: 100%;
          min-height: 90vh;
          max-height: 90vh;
          font-size: 30vw;
          font-weight: 900;
          writing-mode: vertical-rl;
          text-orientation: mixed;
          display: flex;
          align-items: center;
        }
      }

      &.SimpleCard {
        .Content {
          padding: 16px 0;
          font-family: $font-family-monospace;
          font-size: 14px;
          white-space: pre-wrap;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
 .DateTimeDuration {
        width: 150px !important;
 }
}