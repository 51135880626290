.Connect {
  height: 85vh !important;

  .User {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: auto;

    .Search {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  .BGDG {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: auto;

    .Search {
      display: block;
      height: 100%;
      width: 100%;
    }
  }
}