.geocoder--results {
  position: absolute;
  z-index: 10;
  max-width: 100%;
  //padding-right: 17px;
  font-family: $font-family-monospace;
  font-size: 12px;
  margin-top: 2px;
  //display: contents;

  .active {
    background: white;
    color: #101526;
    border-color: #7f7f7f;
  }

  .list-group {
    border: 1px solid #7f7f7f;
    background-color: white;
    border-radius: 5px;
    max-height: 250px;
    overflow-y: scroll;
    padding-top: 10px;
  }

  .list-group .list-group-item:first-child {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }

  .list-group .list-group-item:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  .list-group-item {
    padding: 0.75rem 0.7rem !important;
    border: 1px solid white;
  }
  .list-group-item:hover {
   background-color: antiquewhite;
  }
  .list-group-item:active {
    background-color: antiquewhite;
   }
}

.manager-desktop {
  .mapboxgl-marker {
    text-align: center;

    img {
      cursor: pointer;
    }
  }
}

.mapbox-driver {
  text-align: center;
  z-index: 1000;
  position: fixed;
  top: -50px;
  left: -84px;
  border-radius: 5px;
  line-height: 38px;
  background: $white;
  height: 38px;
  padding: 0 8px;
  width: 150px;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid $secondary-color;
  margin-bottom: 6px;

  &::after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background: white;
    border-right: 1px solid $secondary-color;
    border-bottom: 1px solid $secondary-color;
    transform: rotate(45deg);
    position: absolute;
    left: 68px;
    top: 29px;
  }
}
