.ListDG {
  //margin-top: 136px;
  
  .Container {
    display: flex;
    padding: 0 6% 240px 6%;

    h3 {
      text-transform: uppercase;
            font-size: 30px;
            font-style: italic;
            font-weight: 600;
    }

    #filters {
      // width: 300px;
      position: absolute;
      z-index: 10;

      &.hidden {
        display: none;
      }

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        background: $secondary-color;                                            
        border-left: 1px solid $secondary-color;
        border-top: 1px solid $secondary-color;
        transform: rotate(45deg);
        position: absolute;
        left: 91px;
        top: -6px;
      }
    }

    .List {
      width: 70%;
      padding-right: 60px;
      // min-height: 200px;

      .Header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 28px;

        .Filter {
          button {
            display: flex;
            flex-direction: row;
            align-items: center;
            background: $btn-gray !important;

            .arrow {
              padding-left: 16px;
            }
          }
        }
      }

      .Results {
        display: flex;
        flex-direction: column;

        .Result {
          background: $white;
          border-radius: 5px;
          box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 3px -2px rgba(0, 0, 0, 0.08), 0 3px 4px 0 rgba(0, 0, 0, 0.06);          margin-bottom: 16px;
          padding: 26px 32px;

          .Title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color:$black;
            font-size: 24px; 
            margin-bottom: 23px;
            margin-right: -15px;
            margin-left: -15px;

            h4{
              font-style:uppercase !important;
            }

            .estimatePrice {
              font-weight: 600;
              color:$black;
            }
            
            .badge-service:first-of-type{
              margin-left:16px;
            }

            .badge-service {
              font-family: $font-family-monospace;
              font-style: normal;
              background-color: $white !important;
              color: $black !important;
              height: 24px;
              line-height: 1.5;
              padding: 0 12px;
              margin: .375rem;
              border-radius: 5px;
              box-shadow: unset;
              font-size: 16px;
              font-weight: 400;
              border: solid 1px #545055 !important;
            }

          }

          .Content {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            font-family: $font-family-monospace;
            font-weight:400;
            margin-right: -15px;
            margin-left: -15px;

            .Description {
              p{
                color:$black;
                margin-bottom:8px;
                line-height: 1.5;
              }

              .main_work_zone {
                color: $light-gray;
              }
            }

            .Cta {
              display: flex;
              align-content: flex-end;
              align-items: flex-end;

              button {
                //height: 45px;
              }
            }
          }
        }

        .no-results {
          text-align: center;
          padding: 148px 10% 0 10%;

          h3 {
            margin-bottom: 16px;
          }

            .p {
              font-family: $font-family-monospace;
          }
        }
      }
    }

    .Ad {
      width: 27%;
    }
  }
}

@media screen and (max-width: 992px) {
  .ListDG {
    .Container {
      padding: 0 32px 240px 32px;

      .List {
        padding-right: 16px;
      }
    }
  }
}

// MOBILE
@media screen and (max-width: 768px) {
  .ListDG {
    //margin-top: 72px;
    .Container {
      padding: 0 16px 240px 16px;

      .List {
        width: 100%;
        padding: 0;

        .Header {
          justify-content: flex-end;

          h3 {
            display: none;
          }
        }
      }

      .Ad {
        display: none;
      }
    }
  }
}