// Footer

footer {
  padding: 0 13%;
  &.default {
    background: $default-color;
  }

  .row {
    padding-bottom: 28px;
  }

  .title {
    margin-bottom: 38px;
  }

  h5 {
    &.title {
      font-family: $roboto-font-family;
      font-size: 20px;
      font-weight: 600;
      font-style: italic;
      text-transform: uppercase;
    }
  }

  .title {
    &.list {

      .button {
        display: none;
      }
    }
  }
}

// Mobile
@media screen and (max-width: 768px) { 
  footer {
    padding: 0;


    .container-fluid {
      padding: 0;

      p {
        padding: 0 16px;
      }

      .row {
        margin: 0;
  
        .col-md-4 {
          padding: 0;

          .title{
            &.list {
              padding: 12px 0;
              border-top: 1px solid rgba(84, 80, 85, 1);
              border-bottom: 1px solid rgba(84, 80, 85, 1);

              .button {
                display: block;
                position: relative;
                float: right;
                right: 16px;
                cursor: pointer;
              }
            }
          }
      
      
          ul {
            padding: 18px 0;
            
            &.hidden {
              display: none;
            }
      
            .list-unstyled {
              padding: 0 16px;
              text-align: left;
            }
          }
        }
      }

     
    }

  }

}